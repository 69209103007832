import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import { isPendingReservationUpdate } from "@/hooks/useReservation";
import { I18nKey } from "@/i18n/types";
import { Icon } from "@/tools/icons";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";

type CardStackProps = {
  emptyHeader: ReactNode;
  i18nCountkey: I18nKey;
  Icon: Icon;
  originalCount?: number;
  count: number;
  reservation: VenueReservation;
  cards: JSX.Element[];
};

export default function CardStack({
  cards,
  count,
  emptyHeader,
  i18nCountkey,
  Icon,
  originalCount,
  reservation,
}: CardStackProps) {
  const { t } = useLocalization();

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Typography variant="h5">
          {count === 0
            ? emptyHeader
            : `${t(i18nCountkey, {
                interpolation: {
                  count,
                },
              })} ${
                isPendingReservationUpdate(reservation) &&
                originalCount !== undefined &&
                originalCount !== count
                  ? `${originalCount} → `
                  : ""
              }${count}`}
        </Typography>
        <Icon />
      </Stack>
      <Stack gap={1}>
        {cards.map((card, index) => (
          <React.Fragment key={index}>{card}</React.Fragment>
        ))}
      </Stack>
    </>
  );
}
