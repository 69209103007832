import useDevice from "@/hooks/useDevice";
import useLocalization from "@/hooks/useLocalization";
import useReservation from "@/hooks/useReservation";
import useVenue from "@/hooks/useVenue";
import icons from "@/tools/icons";
import { Button, Stack } from "@mui/material";
import { useMemo } from "react";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import SearchSelect from "./SearchSelect";

type SearchMenuProps = {
  onSearch?: () => Promise<void>;
};

export default function SearchMenu({ onSearch }: SearchMenuProps) {
  const {
    from,
    productId,
    setFrom,
    setProductId,
    setUntil,
    setVenueId,
    until,
    venueId,
    fetchReservations,
    hasSearchParams,
    resetSearch,
  } = useReservation();

  const { venues, venueMap } = useVenue();
  const venue = useMemo(() => venueMap.get(venueId ?? ""), [venueId, venueMap]);
  const products = useMemo(() => venue?.products ?? [], [venue?.products]);
  const { isMobile } = useDevice();
  const { t } = useLocalization();

  return (
    <Stack direction="column" gap={1}>
      <SearchSelect
        value={venueId}
        setValue={setVenueId}
        options={venues}
        label={t("nouns:venue")}
      />
      <SearchSelect
        value={productId}
        setValue={setProductId}
        options={products}
        label={t("nouns:tour")}
      />
      <DateRangePicker
        from={from}
        until={until}
        setFrom={setFrom}
        setUntil={setUntil}
      />
      <Stack direction="row" gap={1}>
        <Button
          fullWidth
          variant="contained"
          onClick={async () => {
            await onSearch?.();
            await fetchReservations();
          }}
        >
          {t("command:search")}
        </Button>
        {isMobile && (
          <Button
            variant="contained"
            color="inherit"
            disabled={!hasSearchParams}
            onClick={async () => {
              await onSearch?.();
              resetSearch();
            }}
          >
            <icons.Close />
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
