import useTimer from "@/hooks/useTimer";
import { Box, IconButton, useTheme } from "@mui/material";
import { TimerButtonProps } from "../types";
import CircularTimer, { CircularTimerProps } from "./CircularTimer";

export type CircularTimerButtonProps = TimerButtonProps &
  Pick<CircularTimerProps, "size">;

export default function CircularTimerButton({
  children,
  onClick,
  sx,
  pause,
  start,
  color,
  disableTimer,
  ...props
}: React.PropsWithChildren<CircularTimerButtonProps>) {
  const timer = useTimer({
    start,
    pause,
    ...("end" in props ? { end: props.end } : { duration: props.duration }),
  });

  const disabled = props.disabled || (!pause && timer.timeUp);

  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  const opacity = theme.custom.iconBadgeOpacity(isLightMode, disabled);

  return (
    <IconButton disabled={disabled} sx={sx} onClick={onClick}>
      <Box sx={{ position: "absolute" }}>
        {!disableTimer && (
          <CircularTimer {...timer} pause={pause} color={color} />
        )}
      </Box>
      <Box
        sx={{
          opacity,
          transition: "opacity 1s linear",
        }}
      >
        {children}
      </Box>
    </IconButton>
  );
}
