import { DEFAULT_COLOR_MODE, DEFAULT_LOCALIZATION } from "@/constants";
import { VenuePortalUser, VenuePortalUserSettings } from "@/graphql/operations";
import { Localization } from "@/i18n/types";
import {
  LocalStorageKey,
  getLocalStorage,
  setLocalStorage,
} from "@/tools/localStorage/localStorage";
import { ColorMode } from "@/types";
import { useMutation, useQuery } from "@apollo/client";
import { SetStateAction, createContext, useCallback, useState } from "react";
import {
  EDIT_VENUE_PORTAL_USER_SETTINGS_MUTATION,
  EditVenuePortalUserSettingsMutationInput,
  EditVenuePortalUserSettingsMutationResponse,
} from "./mutations/editVenuePortalUserSettingsMutation";
import {
  GET_VENUE_PORTAL_USER_SETTINGS_QUERY,
  GetVenuePortalUserSettingsInput,
  GetVenuePortalUserSettingsResponse,
} from "./queries/getVenuePortalUserSettingsQuery";

const defaultSettings: Partial<VenuePortalUserSettings> = {
  iso639_1: DEFAULT_LOCALIZATION,
  theme: DEFAULT_COLOR_MODE,
};

function useSettingsValues() {
  const [userSettingsId, setUserSettingsId] = useState<
    VenuePortalUser["id"] | null
  >(null);

  const [settings, setSettings] = useState<Partial<VenuePortalUserSettings>>({
    iso639_1: getLocalStorage("localization") ?? defaultSettings.iso639_1,
    theme: getLocalStorage("colorMode") ?? defaultSettings.theme,
  });

  useQuery<GetVenuePortalUserSettingsResponse, GetVenuePortalUserSettingsInput>(
    GET_VENUE_PORTAL_USER_SETTINGS_QUERY,
    {
      variables: {
        venuePortalUserId: userSettingsId ?? "",
      },
      onError(error) {
        console.error(error.message);
      },
      onCompleted(data) {
        setSettings({
          ...defaultSettings,
          ...{
            iso639_1: getLocalStorage("localization") ?? undefined,
            theme: getLocalStorage("colorMode") ?? undefined,
          },
          ...data.venuePortalUser.settings,
        });
      },
      skip: !userSettingsId,
    }
  );

  const [editVenuePortalUserSettingsMutation] = useMutation<
    EditVenuePortalUserSettingsMutationResponse,
    EditVenuePortalUserSettingsMutationInput
  >(EDIT_VENUE_PORTAL_USER_SETTINGS_MUTATION);

  const setSetting = useCallback(
    <T, K extends LocalStorageKey | undefined>(
      settingsKey: keyof NonNullable<typeof settings>,
      fallback: K extends undefined ? T : T extends string ? T : never,
      arg: SetStateAction<T>,
      localStorageKey?: K
    ) => {
      const prevSetting =
        (settings?.[settingsKey] as T) ??
        (localStorageKey
          ? (getLocalStorage(localStorageKey) as T)
          : undefined) ??
        fallback;

      const nextSetting =
        typeof arg === "function"
          ? (arg as (prevState: T) => T)(prevSetting)
          : arg;

      if (localStorageKey)
        setLocalStorage(localStorageKey, nextSetting as string);

      setSettings((settings) => ({
        ...settings,
        [settingsKey]: nextSetting,
      }));

      if (!userSettingsId) return;

      editVenuePortalUserSettingsMutation({
        variables: {
          venuePortalUserId: userSettingsId,
          [settingsKey]: nextSetting,
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: "VenuePortalUser",
              id: userSettingsId,
            }),
            fields: {
              settings: (prevSettings) => ({
                ...prevSettings,
                [settingsKey]: nextSetting,
              }),
            },
          });
        },
      });
    },
    [settings, userSettingsId, editVenuePortalUserSettingsMutation]
  );

  const setColorMode = useCallback(
    (arg: SetStateAction<ColorMode>) =>
      setSetting("theme", DEFAULT_COLOR_MODE, arg, "colorMode"),
    [setSetting]
  );
  const setLocalization = useCallback(
    (arg: SetStateAction<Localization>) =>
      setSetting("iso639_1", DEFAULT_LOCALIZATION, arg, "localization"),
    [setSetting]
  );
  const setReceiveReservationEmails = useCallback(
    (arg: SetStateAction<boolean>) =>
      setSetting("receiveReservationEmails", true, arg),
    [setSetting]
  );

  const toggleReceiveReservationEmails = useCallback(
    () => setReceiveReservationEmails((currentSetting) => !currentSetting),
    [setReceiveReservationEmails]
  );

  return {
    setColorMode,
    setLocalization,
    settings,
    toggleReceiveReservationEmails,
    setUserSettingsId,
  };
}

export const SettingsContext = createContext<ReturnType<
  typeof useSettingsValues
> | null>(null);

export default function SettingsProvider({
  children,
}: React.PropsWithChildren) {
  return (
    <SettingsContext.Provider value={useSettingsValues()}>
      {children}
    </SettingsContext.Provider>
  );
}
