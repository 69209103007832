import icons from "@/tools/icons";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type AdditionalInfoProps = { text: string };
export default function AdditionalInfo({ text }: AdditionalInfoProps) {
  return (
    <Card
      sx={{
        padding: "8px",
      }}
    >
      <Stack direction="row" gap={2}>
        <icons.Info fontSize="large" />
        <Typography sx={{ whiteSpace: "pre-line" }}>{text}</Typography>
      </Stack>
    </Card>
  );
}
