import icons from "@/tools/icons";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import LanguageDrawer from "./LanguageDrawer";

export default function LanguageDrawerButton() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setOpenDrawer(true)}
        aria-label="change locale"
      >
        <icons.Translate />
      </IconButton>
      <LanguageDrawer
        open={openDrawer}
        handleClose={() => setOpenDrawer(false)}
      />
    </>
  );
}
