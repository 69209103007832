import { LocalizationContext } from "@/providers/LocalizationProvider/LocalizationContext";
import { useContext } from "react";

export default function useLocalization() {
  const context = useContext(LocalizationContext);
  if (!context) {
    throw new Error(
      "LocalizationContext must be used within a LocalizationProvider"
    );
  }

  return context;
}
