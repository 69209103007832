import icons from "@/tools/icons";
import GuideCard from "./GuideCard";

type PendingGuideCardProps = {
  numPendingGuides: number;
};
export default function PendingGuideCard({
  numPendingGuides,
}: PendingGuideCardProps) {
  return (
    <GuideCard
      name={`${numPendingGuides} guide${
        numPendingGuides === 1 ? "" : "s"
      } unconfirmed`}
      phoneNumber={""}
      FallbackIcon={
        numPendingGuides === 2
          ? icons.TwoPeople
          : numPendingGuides > 2
          ? icons.Group
          : undefined
      }
    />
  );
}
