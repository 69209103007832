import { VenuePortalUser } from "@/graphql/operations";
import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export default function useVenuePortalUserOptions() {
  const getVenuePortalUsersResult = useQuery<GetVenuePortalUsersResponse>(
    GET_VENUE_PORTAL_USERS_QUERY
  );

  const venuePortalUsers = useMemo(
    () => getVenuePortalUsersResult.data?.venuePortalUsers?.records ?? [],
    [getVenuePortalUsersResult.data]
  );

  const venuePortalUserOptions = useMemo(
    () => [
      ...venuePortalUsers.map(({ id, email }) => ({
        id,
        label: email,
      })),
      { id: "", label: null },
    ],
    [venuePortalUsers]
  );

  const venuePortalUserOptionMap = useMemo(
    () => new Map(venuePortalUserOptions.map((user) => [user.id, user])),
    [venuePortalUserOptions]
  );

  return {
    venuePortalUserOptions,
    venuePortalUserOptionMap,
    getVenuePortalUsersResult,
  };
}

export type GetVenuePortalUsersResponse = {
  venuePortalUsers: { records: Pick<VenuePortalUser, "id" | "email">[] };
};

export const GET_VENUE_PORTAL_USERS_QUERY = gql`
  query GetVenuePortalUsers {
    venuePortalUsers {
      records {
        id
        email
      }
    }
  }
`;
