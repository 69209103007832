import { ReservationDetails } from "@/components/ReservationDetails/ReservationDetails";
import { VenueReservation } from "@/graphql/operations";
import useDevice from "@/hooks/useDevice";
import useReservation from "@/hooks/useReservation";
import SearchBar from "@/pages/ReservationPage/components/SearchBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useCallback, useMemo, useState } from "react";
import VirtualReservationTable from "./components/VirtualReservationTable/VirtualReservationTable";

export default function ReservationsPage() {
  const { reservations } = useReservation();

  const { isDesktop } = useDevice();

  const [selectedReservationId, setSelectedReservationId] = useState<
    string | null
  >(null);

  const reservationsById = useMemo(
    () =>
      reservations.reduce((acc, reservation) => {
        acc[reservation.id] = reservation;
        return acc;
      }, {} as Record<VenueReservation["id"], VenueReservation>),
    [reservations]
  );

  const selectReservation = useCallback(
    (id: string) =>
      setSelectedReservationId((previousId) =>
        previousId === id ? null : id ?? null
      ),
    [setSelectedReservationId]
  );

  const selectedReservation = useMemo(
    () =>
      selectedReservationId ? reservationsById[selectedReservationId]! : null,
    [reservationsById, selectedReservationId]
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        gap={"32px"}
        sx={{
          height: "100%",
        }}
      >
        <Stack
          direction="column"
          sx={{
            flexShrink: 0,
            width: isDesktop ? "380px" : "100%",
            borderRight: isDesktop
              ? "1px solid rgba(255, 255, 255, 0.12)"
              : undefined,
          }}
        >
          {isDesktop && <SearchBar />}
          <VirtualReservationTable
            reservations={reservations}
            selectReservation={selectReservation}
            selectedReservation={selectedReservation}
          />
        </Stack>
        {isDesktop && (
          <Box sx={{ flexGrow: 1, paddingRight: "32px", paddingTop: "16px" }}>
            {selectedReservation && (
              <ReservationDetails
                key={selectedReservation.id}
                reservation={selectedReservation}
              />
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
}
