import useLocalization from "@/hooks/useLocalization";
import useSettings from "@/hooks/useSettings";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

export default function NotificationSettings() {
  const { t } = useLocalization();
  const { settings, toggleReceiveReservationEmails } = useSettings();

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!settings?.receiveReservationEmails}
            onClick={toggleReceiveReservationEmails}
          />
        }
        label={t("settings:receiveEmailNotifications")}
      />
      {/* <FormControlLabel control={<Checkbox />} label="Phone" />
  <FormControlLabel control={<Checkbox />} label="Line" /> */}
    </FormGroup>
  );
}
