import useLocalization from "@/hooks/useLocalization";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Dispatch, SetStateAction } from "react";

type SearchSelectProps<T> = {
  value: string | null;
  setValue: Dispatch<SetStateAction<string | null>>;
  options: T[];
  label: string;
};

export default function SearchSelect<T extends { id: string; name: string }>({
  value,
  setValue,
  options,
  label,
}: SearchSelectProps<T>) {
  const { t } = useLocalization();
  return (
    options.length > 1 && (
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value ?? ""}
          label={label}
          onChange={({ target }) => setValue(target.value || null)}
          fullWidth
          aria-label={t("command:selectWhat", {
            interpolation: { what: label },
          })}
        >
          <MenuItem value={""} sx={{ opacity: 0.5 }}>
            <em>{t("nouns:all")}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
}
