import { fetchUser } from "@/tools/jwtToken/jwtToken";
import { GrowthBook, configureCache } from "@growthbook/growthbook-react";

configureCache({
  // The localStorage key the cache will be stored under
  cacheKey: "gbFeaturesCache",
  // Consider features stale after this much time (5 minutes)
  staleTTL: 1000 * 60 * 5,
  // Cached features older than this will be ignored (24 hours)
  maxAge: 1000 * 60 * 60 * 24,
  // Consider a page "idle" when it is hidden for this long (default 20 seconds)
  idleStreamInterval: 20000,
});

export const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-0n6T109tWvuaPXT",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
  attributes: fetchUser(),
});

growthbook.init({ timeout: 1000 });
