import { gql } from "@apollo/client";

export type SendReservationInquiryEmailInput = {
  reservationId: string;
  body: string;
};

export type SendReservationInquiryEmailResponse = {
  sendReservationInquiryEmail: boolean;
};

export const SEND_RESERVATION_INQUIRY_EMAIL_MUTATION = gql`
  mutation SendReservationInquiryEmailMutation(
    $reservationId: ID!
    $body: String!
  ) {
    sendReservationInquiryEmail(reservationId: $reservationId, body: $body)
  }
`;
