import { Theme } from "@mui/material";

type PaletteColorKeys =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success";

export function getColorFromTheme(
  theme: Theme,
  color: string,
  subColor: keyof Theme["palette"][PaletteColorKeys] = "main",
  opacity: number = 1
): string {
  if (theme.palette[color as PaletteColorKeys])
    return `${theme.palette[color as PaletteColorKeys][subColor]}${Math.round(
      opacity * 255
    ).toString(16)}`;

  return color;
}
