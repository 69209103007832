import { VenuePortalUser } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type GetUserVenuesInput = {
  venuePortalUserId: string;
};
export type GetUserVenuesResponse = {
  venuePortalUser: Pick<VenuePortalUser, "id" | "venues">;
};

export const GET_USER_VENUES_QUERY = gql`
  query GetVenuePortalUserVenueReservations($venuePortalUserId: ID) {
    venuePortalUser(id: $venuePortalUserId) {
      id

      venues {
        id
        name

        products {
          id
          name
        }
      }
    }
  }
`;
