import { Box, Drawer, DrawerProps, Toolbar } from "@mui/material";
import SearchMenu from "../../../SearchMenu/SearchMenu";

type NavigationDrawerProps = Pick<DrawerProps, "open"> & {
  handleClose: () => void;
};

export default function SearchDrawer({
  open,
  handleClose,
}: NavigationDrawerProps) {
  return (
    <Drawer open={open} onClose={handleClose} anchor="top">
      <Toolbar />
      <Box sx={{ padding: "10px 8px 8px 8px" }}>
        <SearchMenu onSearch={async () => handleClose()} />
      </Box>
    </Drawer>
  );
}
