import useLocalization from "@/hooks/useLocalization";
import { localizations } from "@/i18n/types";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

type LanguageDrawerProps = Pick<DrawerProps, "open"> & {
  handleClose: () => void;
};

export default function LanguageDrawer({
  open,
  handleClose,
}: LanguageDrawerProps) {
  const { t, setLocalization: setLanguage } = useLocalization();
  return (
    <Drawer open={open} onClose={handleClose} anchor="bottom">
      <List>
        {localizations.map((language) => (
          <ListItem key={language} disablePadding>
            <ListItemButton
              onClick={() => {
                setLanguage(language);
                handleClose();
              }}
            >
              <ListItemText
                primary={t("identity:name", { localization: language })}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
