import VenuePortalUserOptionsAutocomplete from "@/components/VenuePortalUserOptionsAutocomplete/VenuePortalUserOptionsAutocomplete";
import { Drawer, DrawerProps, Toolbar } from "@mui/material";

type ImpersonationDrawerProps = Pick<DrawerProps, "open"> & {
  handleClose: () => void;
};

export default function ImpersonationDrawer({
  open,
  handleClose,
}: ImpersonationDrawerProps) {
  return (
    <Drawer open={open} onClose={handleClose} anchor="top">
      <Toolbar />
      <VenuePortalUserOptionsAutocomplete onChangeCallback={handleClose} />
    </Drawer>
  );
}
