import styled from "@emotion/styled";
import { StaticDatePicker } from "@mui/x-date-pickers";

const WideStaticDatePicker = styled(StaticDatePicker)((_) => ({
  "& .MuiDateCalendar-root": {
    width: "unset",
    margin: "unset",

    "& .MuiBox-root": {
      flex: 1,
      textAlign: "center",
    },
  },
  "& .MuiDayCalendar-header": { justifyContent: "space-evenly" },
  "& .MuiDayCalendar-weekContainer": { justifyContent: "space-evenly" },
}));

export default WideStaticDatePicker;
