import useDevice from "@/hooks/useDevice";
import Stack from "@mui/material/Stack";
import MobileHeader from "../MobileHeader/MobileHeader";
import SideBar from "../SideBar/SideBar";
import MainContent from "./MainContent";

type PageTemplateProps = {
  canShowHeader?: boolean;
  canShowSideBar?: boolean;
};

export default function PageTemplate({
  canShowSideBar = true,
  canShowHeader = true,
  children,
}: React.PropsWithChildren<PageTemplateProps>) {
  const { isDesktop, isMobile } = useDevice();

  return (
    <Stack direction="row" sx={{ overflowX: "hidden" }}>
      {isDesktop && canShowSideBar && <SideBar />}

      <Stack direction="column" sx={{ height: "100vh", width: "100%" }}>
        {isMobile && canShowHeader && <MobileHeader />}

        <MainContent>{children}</MainContent>
      </Stack>
    </Stack>
  );
}
