import { VenuePortalUser } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import useNotification from "@/hooks/useNotification";
import useSettings from "@/hooks/useSettings";
import { Localization } from "@/i18n/types";
import { growthbook } from "@/setup/growthBook";
import { sendGaEvent, setGaUserId } from "@/tools/analytics";
import {
  clearJwtToken,
  fetchUser,
  setJwtToken,
} from "@/tools/jwtToken/jwtToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { VenuePortalError } from "@shared/errors";
import { createContext, useCallback, useMemo, useState } from "react";
import {
  EDIT_PASSWORD_MUTATION,
  EditPasswordMutationInput,
  EditPasswordMutationResponse,
} from "./mutations/editPasswordMutation";
import {
  LOGIN_MUTATION,
  LoginMutationInput,
  LoginMutationResponse,
} from "./mutations/loginMutation";
import {
  REQUEST_PASSWORD_MUTATION,
  RequestPasswordInput,
  RequestPasswordReponse,
} from "./mutations/requestPasswordMutation";
import {
  GET_PASSWORD_REQUEST_QUERY,
  GetPasswordRequestQueryInput,
  GetPasswordRequestQueryResponse,
} from "./queries/getRequestPasswordEmailQuery";

function useUserValues() {
  const { t } = useLocalization();
  const { addNotification } = useNotification();

  const [user, setUser] = useState(fetchUser());
  const { setUserSettingsId } = useSettings();

  const impersonateUser = useCallback(
    (id: VenuePortalUser["id"] | undefined) => {
      setUser((user) => ({ ...user, userId: id }));
    },
    [setUser]
  );

  const updateUser = useCallback(() => {
    const user = fetchUser();
    setUser(user);
    setUserSettingsId(user.userId ?? null);
    setGaUserId(user.userId ?? null);
    if (!user.userId) return;
    sendGaEvent("login");
    growthbook.setAttributes(user);
    Sentry.setUser({ id: user.userId });
  }, [setUserSettingsId]);

  const [loginMutation] = useMutation<
    LoginMutationResponse,
    LoginMutationInput
  >(LOGIN_MUTATION);

  const [editPasswordMutation] = useMutation<
    EditPasswordMutationResponse,
    EditPasswordMutationInput
  >(EDIT_PASSWORD_MUTATION);

  const [requestPasswordMutation] = useMutation<
    RequestPasswordReponse,
    RequestPasswordInput
  >(REQUEST_PASSWORD_MUTATION);

  const [getRequestPasswordEmailQuery] = useLazyQuery<
    GetPasswordRequestQueryResponse,
    GetPasswordRequestQueryInput
  >(GET_PASSWORD_REQUEST_QUERY);

  const login = useCallback(
    async (email: string, password: string, onCompleted?: () => void) => {
      const { data } = await loginMutation({
        variables: {
          email,
          password,
        },
      });
      if (data?.login.jwtToken) {
        setJwtToken(data.login.jwtToken);
        updateUser();
        addNotification({
          severity: "success",
          message: t("notification:login.loggedIn"),
        });
        onCompleted?.();
      }

      if (data?.login.error) {
        addNotification({
          severity: "error",
          message: `${t("notification:login.failedToLogin")}: ${t(
            `error:${data.login.error}`
          )}`,
        });
      }

      return { error: data?.login.error };
    },
    [loginMutation, updateUser, addNotification, t]
  );

  const logout = useCallback(() => {
    clearJwtToken();
    updateUser();
    location.reload();
    addNotification({
      severity: "success",
      message: t("notification:login.loggedOut"),
    });
  }, [updateUser, addNotification, t]);

  const editPassword = useCallback(
    async (variables: EditPasswordMutationInput, onCompleted?: () => void) => {
      await editPasswordMutation({
        variables,
        onCompleted() {
          addNotification({
            severity: "success",
            message: t("notification:password.savedPassword"),
          });
          onCompleted?.();
        },
        onError(error) {
          addNotification({
            severity: "error",
            message: `${t("notification:password.failedToSavePassword")}: ${t(
              `error:${error.message as VenuePortalError}`
            )}`,
          });
        },
      });
    },
    [editPasswordMutation, addNotification, t]
  );

  const requestPassword = useCallback(
    async (variables: RequestPasswordInput, onCompleted?: () => void) => {
      await requestPasswordMutation({
        variables,
        onCompleted() {
          addNotification({
            severity: "success",
            message: t("notification:password.sentPasswordResetEmail"),
          });
          onCompleted?.();
        },
        onError(error) {
          addNotification({
            severity: "error",
            message: `${t(
              "notification:password.failedToSendPasswordResetEmail"
            )}: ${t(`error:${error.message as VenuePortalError}`)}`,
          });
        },
      });
    },
    [requestPasswordMutation, addNotification, t]
  );

  const getRequestPasswordEmail = useCallback(
    async (variables: GetPasswordRequestQueryInput) => {
      const { data, loading, error } = await getRequestPasswordEmailQuery({
        variables,
      });
      return {
        isUsed: !!data?.venuePortalPasswordRequest?.usedAt,
        isExpired: data?.venuePortalPasswordRequest?.isExpired,
        email: data?.venuePortalPasswordRequest?.user?.email,
        iso639_1: data?.venuePortalPasswordRequest?.user?.settings.iso639_1 as
          | Localization
          | undefined,
        loading,
        error,
      };
    },
    [getRequestPasswordEmailQuery]
  );

  const isMtUser = useMemo(() => !!user.mtId, [user.mtId]);
  const isSubUser = useMemo(() => !!user.parentId, [user.parentId]);

  return {
    editPassword,
    getRequestPasswordEmail,
    impersonateUser,
    isMtUser,
    isSubUser,
    login,
    logout,
    requestPassword,
    user,
  };
}

export const UserContext = createContext<ReturnType<
  typeof useUserValues
> | null>(null);

export default function UserProvider({ children }: React.PropsWithChildren) {
  return (
    <UserContext.Provider value={useUserValues()}>
      {children}
    </UserContext.Provider>
  );
}
