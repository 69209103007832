import useColorMode from "@/hooks/useColorMode/useColorMode";
import useLocalization from "@/hooks/useLocalization";
import icons from "@/tools/icons";
import Button from "@mui/material/Button";

export default function ColorModeSettings() {
  const { t } = useLocalization();
  const { colorMode, toggleColorMode } = useColorMode();

  const themeMode =
    colorMode === "dark" ? t("settings:mode.dark") : t("settings:mode.light");

  return (
    <Button
      fullWidth
      startIcon={
        colorMode === "dark" ? <icons.DarkMode /> : <icons.LightMode />
      }
      variant="contained"
      onClick={toggleColorMode}
      aria-label={t("command:setWhat", {
        interpolation: { what: `${t("nouns:theme")} ${themeMode}` },
      })}
    >
      {t("command:toggleWhat", {
        interpolation: {
          what: t("nouns:theme"),
        },
      })}
    </Button>
  );
}
