import { errors } from "@shared/errors";

// The enLocale is the source of truth for translation fields
// and is used as a fallback.
//
// in other words, adding or removing fields in the enLocale const
// will reflect in the I18nLocale & I18nKey types
//
// Please keep the enLocale in alphabetical order
//
// ----
// i18next technical notes:
// 1. adding a minus in front of interpolation like {{- when}}
//    will escape special characters, so slashes/ can be used

export const enLocale = {
  identity: { name: "English", flag: "🇬🇧" },

  command: {
    accept: "accept",
    acceptWhat: "accept {{what}}",
    closeWhat: "close {{what}}",
    confirmWhat: "confirm {{what}}",
    editWhat: "edit {{what}}",
    logIn: "log in",
    logOut: "log out",
    openWhat: "open {{what}}",
    reject: "reject",
    rejectWhat: "reject {{what}}",
    resetWhat: "reset {{what}}",
    requestWhat: "request {{what}}",
    save: "save",
    search: "search",
    selectWhat: "select {{what}}",
    sendWhat: "send {{what}}",
    sendingWhat: "sending {{what}}",
    setWhat: "set {{what}}",
    toggleWhat: "toggle {{what}}",
  },

  date: {
    date: "{{year}}/{{month}}/{{day}}",
    Fri: "Fri",
    Mon: "Mon",
    Sat: "Sat",
    Sun: "Sun",
    Thu: "Thu",
    Tue: "Tue",
    Wed: "Wed",
  },

  error: {
    [errors.invalidCredentials]: "invalid credentials",
    [errors.invalidPassword]: "invalid password",
    [errors.missingCredentials]: "missing credentials",
    [errors.unauthorized]: "unauthorized",
    [errors.graceTimerExpired]: "reset timer expired",
    [errors.passwordRequestExpired]:
      "This password request is no longer valid. Please try requesting a new password again.",
    [errors.passwordRequestUsed]:
      "This password request is already used. Please try requesting a new password again.",
    [errors.passwordRequestFailed]:
      "This password request could not be processed. Please try requesting a new password again.",
  },

  messages: {
    feedbackExplanation:
      "We value your input on the Venue Portal! How has your experience been so far? What aspects do you like or find challenging? Please share your feedback with us, so we can continue to enhance the product to better meet your needs.",
    feedbackGratitude: "Thank you for your feedback",
    inquiryExplanation:
      "Have questions about this reservation? Feel free to ask them here, and we will respond as quickly as possible.",
  },

  notification: {
    feedback: {
      failedToSendFeedback: "could not send feedback",
      sentFeedback: "sent feedback",
    },
    inquiry: {
      failedToSendInquiry: "could not send inquiry",
      sentInquiry: "sent inquiry",
    },
    login: {
      failedToLogin: "failed to login",
      loggedIn: "logged in",
      loggedOut: "logged out",
    },
    password: {
      failedToSavePassword: "could not save password",
      failedToSendPasswordResetEmail: "could not send password reset email",
      savedPassword: "saved password",
      sentPasswordResetEmail: "sent password reset email",
    },
    reservation: {
      acceptedReservation: "accepted reservation",
      failedToUpdateReservation: "could not update reservation",
      rejectedReservation: "rejected reservation",
      resetReservation: "reset reservation",
    },
  },

  page: {
    feedback: "feedback",
    home: "home",
    impersonate: "impersonate",
    settings: "settings",
  },

  passwordValidation: {
    confirmationPasswordMismatch: "matching confirmation password",
    passwordMinimumLength: "at least 8 characters long",
    passwordNeedsLowercase: "at least one lower case letter",
    passwordNeedsSpecialCharacter: "at least one special character",
    passwordNeedsUppercase: "at least one upper case letter",
    passwordShould: "password should be",
  },

  reservation: {
    accepted: "accepted",
    answeredAt: "{{answer}} at",
    cancelled: "cancelled",
    answeredAtWhen: "{{answer}} at {{- when}}",
    answeredAtWhenByWho: "{{answer}} at {{- when}} by {{who}}",
    endOfReservations: "End of reservations",
    guest_one: "guest",
    guest_other: "guests",
    guide_one: "guide",
    guide_other: "guides",
    noGuideAssigned: "No guide assigned",
    noReservations: "No reservations",
    pending: "pending",
    rejected: "rejected",
    unanswered: "unanswered",
    unconfirmedWhat: "No {{what}} confirmed yet",
    updateRequestedAt: "Update requested at",
    voiceover: {
      reservationDateTimeSummary: "Reservation date: {{date}}, Time: {{time}}",
      reservationInfo:
        "Number of people: {{numGuests}} {{guestLabel}}, {{numGuides}} {{guideLabel}}, Guide name: {{guideName}}",
    },
  },

  settings: {
    mode: { dark: "dark", light: "light" },
    languageSettings: "language settings",
    notificationSettings: "notification settings",
    password: {
      current: "current password",
      forgot: "forgot password",
      new: "new password",
    },
    receiveEmailNotifications: "receive email notifications",
  },

  nouns: {
    all: "all",
    contact: "contact",
    email: "email",
    feedback: "feedback",
    from: "from",
    inquiry: "inquiry",
    language: "language",
    MagicalTrip: "MagicalTrip",
    password: "password",
    profile: "profile",
    reservation: "reservation",
    searchMenu: "reservation search menu",
    siteName: "Venue Portal",
    theme: "theme",
    tour: "tour",
    until: "until",
    venue: "venue",
  },
};
