import useLocalization from "@/hooks/useLocalization";
import useNotification from "@/hooks/useNotification";
import { sendGaEvent } from "@/tools/analytics";
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  useMutation,
} from "@apollo/client";
import { createContext, useCallback } from "react";
import { Localization } from "../../i18n/types";
import {
  SEND_FEEDBACK_EMAIL_MUTATION,
  SendVenuePortalFeedbackEmailInput,
  SendVenuePortalFeedbackEmailResponse,
} from "./mutations/sendFeedbackEmailMutation";
import {
  SEND_RESERVATION_INQUIRY_EMAIL_MUTATION,
  SendReservationInquiryEmailInput,
  SendReservationInquiryEmailResponse,
} from "./mutations/sendReservationInquiryEmailMutation";

export type MailerContextProps = {
  localization?: Localization;
};

function useMailerValues() {
  const { t } = useLocalization();
  const { addNotification } = useNotification();

  const [
    sendVenuePortalFeedbackEmailMutation,
    sendVenuePortalFeedbackEmailResult,
  ] = useMutation<
    SendVenuePortalFeedbackEmailResponse,
    SendVenuePortalFeedbackEmailInput
  >(SEND_FEEDBACK_EMAIL_MUTATION);

  const [
    sendReservationInquiryEmailMutation,
    sendReservationInquiryEmailResult,
  ] = useMutation<
    SendReservationInquiryEmailResponse,
    SendReservationInquiryEmailInput
  >(SEND_RESERVATION_INQUIRY_EMAIL_MUTATION);

  const sendVenuePortalFeedbackEmail = useCallback(
    (
      body: string,
      options?: MutationFunctionOptions<
        SendVenuePortalFeedbackEmailResponse,
        SendVenuePortalFeedbackEmailInput,
        DefaultContext,
        ApolloCache<unknown>
      >
    ) =>
      sendVenuePortalFeedbackEmailMutation({
        variables: {
          body,
        },
        ...options,
      }).then(
        () => {
          sendGaEvent("send_feedback");
          addNotification({
            severity: "success",
            message: t("notification:feedback.sentFeedback"),
          });
        },
        () =>
          addNotification({
            severity: "error",
            message: t("notification:feedback.failedToSendFeedback"),
          })
      ),

    [sendVenuePortalFeedbackEmailMutation, addNotification, t]
  );

  const sendReservationInquiryEmail = useCallback(
    (
      reservationId: string,
      body: string,
      options?: MutationFunctionOptions<
        SendReservationInquiryEmailResponse,
        SendReservationInquiryEmailInput,
        DefaultContext,
        ApolloCache<unknown>
      >
    ) =>
      sendReservationInquiryEmailMutation({
        variables: {
          reservationId,
          body,
        },
        onCompleted: () => {
          sendGaEvent("send_inquiry");
          addNotification({
            severity: "success",
            message: t("notification:inquiry.sentInquiry"),
          });
        },
        onError: () => {
          addNotification({
            severity: "error",
            message: t("notification:inquiry.failedToSendInquiry"),
          });
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: "VenueReservation",
              id: reservationId,
            }),
            fields: {
              latestInquiry: () => {
                body;
              },
            },
          });
        },
        ...options,
      }),
    [sendReservationInquiryEmailMutation, addNotification, t]
  );

  return {
    sendVenuePortalFeedbackEmail,
    sendVenuePortalFeedbackEmailResult,
    sendReservationInquiryEmail,
    sendReservationInquiryEmailResult,
  };
}

export const MailerContext = createContext<ReturnType<
  typeof useMailerValues
> | null>(null);

export default function MailerProvider({ children }: React.PropsWithChildren) {
  return (
    <MailerContext.Provider value={useMailerValues()}>
      {children}
    </MailerContext.Provider>
  );
}
