import { gql } from "@apollo/client";

export type EditVenueReservationMutationInput = {
  id: string;
  answer: string | null;
};
export type EditVenueReservationMutationResponse = {
  editVenueReservationAnswer: boolean;
};
export const EDIT_VENUE_RESERVATION_ANSWER_MUTATION = gql`
  mutation EditVenueReservationAnswer($id: ID!, $answer: String) {
    editVenueReservationAnswer(id: $id, answer: $answer)
  }
`;
