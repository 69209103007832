import axios from "axios";
import { MediaType } from "./types";

const s3url = "https://magical-trip.s3.ap-northeast-1.amazonaws.com/";
const cloudinaryApiKey = process.env.CLOUDINARY_API_KEY as string;
const cloudinarySecret = process.env.CLOUDINARY_API_SECRET as string;

const defaultTransformation = {
  autoFormat: true,
  autoQuality: true,
  fill: true,
} as const;

export function getCloudinaryUrl(
  type: MediaType,
  url: string,
  transformations?: Transformation | Transformation[],
  applyDefaultTransformation: boolean = true,
  cloudName: string = "dbm1qiew0"
) {
  if (!url) return "";

  const cloudinaryUrl = `https://res.cloudinary.com/${cloudName}`;

  // coalesce transformation into an array
  const transformationsAsArray =
    transformations === undefined
      ? [{}]
      : transformations instanceof Array
      ? transformations
      : [transformations];

  // if apply default transformation it true, apply it to the first transformation
  if (applyDefaultTransformation)
    transformationsAsArray[0] = {
      ...transformationsAsArray[0],
      ...defaultTransformation,
    };

  const transformationQuery = transformationsAsArray
    .map(parseTransformation)
    .join("/");

  if (url.includes(s3url)) {
    const media = url.split(s3url)[1];
    return `${cloudinaryUrl}/${type}/upload/${transformationQuery}/s3bucket/${media}`;
  } else if (url.includes(cloudinaryUrl) && url.includes("/upload/")) {
    // FIXME doesn't work for videos
    return url.replace(
      `/${cloudName}/image/upload/`,
      `/${cloudName}/image/upload/${transformationQuery}/`
    );
  } else {
    return `${cloudinaryUrl}/${type}/upload/${transformationQuery}/${url}`;
  }
}

export async function uploadMedia(
  file: File,
  folder?: string,
  fileName?: string,
  cloudName: string = "dbm1qiew0"
): Promise<{ url?: string; error?: string }> {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", cloudinaryApiKey);

    const timeStamp = String(Math.floor(new Date().getTime() / 1000));
    formData.append("timestamp", timeStamp);

    const publicId = `${folder}/${fileName ?? crypto.randomUUID()}`;
    if (folder) formData.append("public_id", publicId);

    formData.append(
      "signature",
      await calculateSHA256(
        `${
          folder ? `public_id=${publicId}` : ""
        }&timestamp=${timeStamp}${cloudinarySecret}`
      )
    );

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
      formData
    );

    if (response.data && response.data.secure_url)
      return { url: response.data.secure_url };
    else return { error: response.data };
  } catch (error) {
    return { error: (error as Error).message };
  }
}

const parseTransformation = (transformation: Transformation) => {
  const transformationMap: {
    [key in keyof Required<Transformation>]: (args: any) => string;
  } = {
    width: (width: number) => `w_${width}`,
    height: (height: number) => `h_${height}`,
    autoFormat: (autoFormat: boolean) => (autoFormat ? `f_auto` : ""),
    autoQuality: (autoQuality: boolean) => (autoQuality ? `q_auto` : ""),
    autoWidth: (autoWidth: boolean) => (autoWidth ? `w_auto` : ""),
    autoHeight: (_: boolean) => "",
    circle: (circle: boolean) => (circle ? "r_max" : ""),
    fill: (fill: boolean) => (fill ? "c_fill" : ""),
  };

  const transformationQuery = Object.entries(transformation)
    .filter(([_, args]) => args !== null && args !== undefined)
    .map(([key, args]) => transformationMap[key as keyof Transformation](args))
    .filter(Boolean)
    .join(",");

  return transformationQuery;
};

export type Transformation = {
  width?: number;
  height?: number;
  autoWidth?: boolean;
  autoHeight?: boolean;
  autoFormat?: boolean;
  autoQuality?: boolean;
  circle?: boolean;
  fill?: boolean;
};

// TODO fix package setup and import this from mt-crypto
const calculateSHA256 = async (data: string) => {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};
