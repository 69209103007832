import { VenueContext } from "@/providers/VenueProvider/VenueContext";
import { useContext } from "react";

export default function useVenue() {
  const context = useContext(VenueContext);
  if (!context) {
    throw new Error("VenueContext must be used within a VenueProvider");
  }

  return context;
}
