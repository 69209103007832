import { Product, Venue, VenueReservationAnswer } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import useNotification from "@/hooks/useNotification";
import useUser from "@/hooks/useUser";
import { sendGaEvent } from "@/tools/analytics";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReservationsContext } from "./ReservationsContext";
import {
  EDIT_VENUE_RESERVATION_ANSWER_MUTATION,
  EditVenueReservationMutationInput,
  EditVenueReservationMutationResponse,
} from "./mutations/EditVenueReservationAnswer";
import {
  GET_VENUE_RESERVATION_QUERY,
  GetVenueReservationQueryInput,
  GetVenueReservationQueryResponse,
} from "./queries/GetVenueReservationQuery";
import {
  GET_VENUE_RESERVATIONS_QUERY,
  GetVenueReservationsQueryInput,
  GetVenueReservationsQueryResponse,
} from "./queries/GetVenueReservationsQuery";

const PAGINATION_SIZE = 20 as const;

export default function ReservationsProvider({
  children,
}: React.PropsWithChildren) {
  const {
    user: { userId },
  } = useUser();

  const { t, localization } = useLocalization();
  const { addNotification } = useNotification();

  const [prevUserId, setPrevUserId] = useState<string | undefined>(undefined);
  const [from, setFrom] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);
  const [venueId, setVenueId] = useState<Venue["id"] | null>(null);
  const [productId, setProductId] = useState<Product["id"] | null>(null);
  const searchVariables = useMemo(
    () => ({
      from: from ?? undefined,
      until: until ?? undefined,
      venueId: venueId ?? undefined,
      productId: productId ?? undefined,
    }),
    [from, until, venueId, productId]
  );

  const defaultVariables = useMemo(
    () => ({
      venuePortalUserId: userId!,
      reservationLimit: PAGINATION_SIZE,
      lang: localization,
    }),
    [localization, userId]
  );

  const [
    fetchReservationsQuery,
    { data, error, loading, fetchMore: fetchMoreReservationsQuery },
  ] = useLazyQuery<
    GetVenueReservationsQueryResponse,
    GetVenueReservationsQueryInput
  >(GET_VENUE_RESERVATIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const apolloClient = useApolloClient();

  const fetchReservations = useCallback(
    async (overrideSearch?: typeof searchVariables) => {
      apolloClient.cache.modify({
        id: apolloClient.cache.identify({
          __typename: "VenuePortalUser",
          id: userId,
        }),
        fields: {
          reservations(_, { DELETE }) {
            return DELETE;
          },
        },
      });
      await fetchReservationsQuery({
        variables: {
          ...defaultVariables,
          reservationOffset: 0,
          search: { ...searchVariables, ...overrideSearch },
        },
        fetchPolicy: "network-only",
      });
    },
    [
      fetchReservationsQuery,
      defaultVariables,
      apolloClient.cache,
      searchVariables,
      userId,
    ]
  );

  const [fetchReservation, fetchReservationResult] = useLazyQuery<
    GetVenueReservationQueryResponse,
    GetVenueReservationQueryInput
  >(GET_VENUE_RESERVATION_QUERY);

  useEffect(() => {
    if (userId && userId !== prevUserId) {
      fetchReservations();
    }
  }, [userId, prevUserId, fetchReservations]);

  useEffect(() => {
    if (userId !== prevUserId) setPrevUserId(userId);
  }, [userId, prevUserId]);

  const [editVenueReservationAnswerMutation] = useMutation<
    EditVenueReservationMutationResponse,
    EditVenueReservationMutationInput
  >(EDIT_VENUE_RESERVATION_ANSWER_MUTATION);

  const reservations = useMemo(
    () => data?.venuePortalUser?.reservations.records ?? [],
    [data]
  );

  const totalReservations = useMemo(
    () => data?.venuePortalUser?.reservations.totalRecords ?? 0,
    [data]
  );

  const fetchMoreReservations = useCallback(async () => {
    await fetchMoreReservationsQuery({
      variables: {
        ...defaultVariables,
        reservationOffset: reservations.length,
        search: searchVariables,
      },
    });
  }, [
    fetchMoreReservationsQuery,
    defaultVariables,
    reservations,
    searchVariables,
  ]);

  const canFetchMoreReservations = useMemo(
    () => reservations.length < totalReservations,
    [reservations, totalReservations]
  );

  const [prevPendingReservationsCount, setPrevPendingReservationsCount] =
    useState(0);

  const pendingReservationsCount = useMemo(() => {
    if (!data) return prevPendingReservationsCount;
    const count = data.venuePortalUser.pendingReservationsCount;
    setPrevPendingReservationsCount(count);
    return count;
  }, [data, prevPendingReservationsCount]);

  const editVenueReservationAnswer = useCallback(
    (reservationId: string, answer: `${VenueReservationAnswer}` | null) =>
      editVenueReservationAnswerMutation({
        variables: {
          id: reservationId,
          answer,
        },
        onCompleted() {
          sendGaEvent(
            `${translateReservationAnswer([
              "accept",
              "reject",
              "reset",
            ] as const)(answer)}_reservation`,
            {
              reservation_id: reservationId,
            }
          );
          addNotification({
            severity: "success",
            message: t(
              `notification:reservation.${translateReservationAnswer([
                "accepted",
                "rejected",
                "reset",
              ] as const)(answer)}Reservation`
            ),
          });
        },
        onError() {
          addNotification({
            severity: "error",
            message: t("notification:reservation.failedToUpdateReservation"),
          });
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: "VenueReservation",
              id: reservationId,
            }),
            fields: {
              answer: () => answer,
              reservationAnswerReceived: () => (answer ? Date.now() : null),
            },
          });
          cache.modify({
            id: cache.identify({
              __typename: "VenuePortalUser",
              id: userId,
            }),
            fields: {
              pendingReservationsCount: (value: number) =>
                value + (Number(!answer) * 2 - 1),
            },
          });
        },
      }),
    [editVenueReservationAnswerMutation, userId, addNotification, t]
  );

  const acceptReservation = useCallback(
    (reservationId: string) => {
      editVenueReservationAnswer(reservationId, "ACCEPTED");
    },
    [editVenueReservationAnswer]
  );

  const rejectReservation = useCallback(
    (reservationId: string) => {
      editVenueReservationAnswer(reservationId, "NOT_ACCEPTED");
    },
    [editVenueReservationAnswer]
  );

  const resetReservation = useCallback(
    (reservationId: string) => {
      editVenueReservationAnswer(reservationId, null);
    },
    [editVenueReservationAnswer]
  );

  const hasSearchParams = useMemo(
    () => !!(from || until || productId || venueId),
    [from, until, productId, venueId]
  );

  const resetSearch = useCallback(async () => {
    if (!hasSearchParams) return;

    setFrom(null);
    setUntil(null);
    setProductId(null);
    setVenueId(null);
    await fetchReservations({
      from: undefined,
      until: undefined,
      productId: undefined,
      venueId: undefined,
    });
  }, [fetchReservations, hasSearchParams]);

  return (
    <ReservationsContext.Provider
      value={{
        acceptReservation,
        canFetchMoreReservations,
        error,
        fetchMoreReservations,
        fetchReservation,
        fetchReservationResult,
        fetchReservations,
        from,
        hasSearchParams,
        loading,
        pendingReservationsCount,
        productId,
        rejectReservation,
        reservations,
        resetReservation,
        resetSearch,
        setFrom,
        setProductId,
        setUntil,
        setVenueId,
        totalReservations,
        until,
        venueId,
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
}

const translateReservationAnswer =
  <Accepted, NotAccepted, Reset>([accepted, notAccepted, reset]: readonly [
    Accepted,
    NotAccepted,
    Reset
  ]) =>
  (answer: `${VenueReservationAnswer}` | null) =>
    ({
      ACCEPTED: accepted,
      NOT_ACCEPTED: notAccepted,
      RESET: reset,
    }[answer ?? "RESET"] as Accepted | NotAccepted | Reset);
