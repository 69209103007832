import { visualRoutes } from "@/Router/routes";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";
import {
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

type NavigationDrawerProps = Pick<DrawerProps, "open"> & {
  handleClose: () => void;
};

export default function NavigationDrawer({
  open,
  handleClose,
}: NavigationDrawerProps) {
  const navigate = useNavigate();
  const { logout } = useUser();

  const currentPath = useLocation().pathname;

  return (
    <Drawer open={open} onClose={handleClose} anchor="top">
      <Toolbar />
      <List>
        {visualRoutes.map(({ path, visual: { id, icon } }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton
              disabled={currentPath === path}
              onClick={() => {
                navigate(path);
                handleClose();
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={id} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider sx={{ marginTop: "12px" }} />
        <ListItem onClick={logout}>
          <ListItemIcon>
            <icons.Logout />
          </ListItemIcon>
          <ListItemText primary="log out" />
        </ListItem>
      </List>
    </Drawer>
  );
}
