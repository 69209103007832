import useTimer from "@/hooks/useTimer";
import { getColorFromTheme } from "@/tools/theme/getColor";
import { useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { TimerButtonProps } from "../types";

const DASH_ARRAY = 113 as const;

const DECREASE_OPACITY_PERCENTAGE_BREAKPOINT = 5;

export type CircularTimerProps = Pick<
  TimerButtonProps,
  "pause" | "color" | "thickness"
> & {
  size?: number;
} & Pick<
    ReturnType<typeof useTimer>,
    | "progressInPercentage"
    | "expectedNextProgressInPercentage"
    | "expectedNextTimeLeftInPercentage"
  >;

export default function CircularTimer({
  size = 41,
  thickness = 6,
  color = "primary",
  progressInPercentage,
  expectedNextProgressInPercentage,
  expectedNextTimeLeftInPercentage,
  pause,
}: CircularTimerProps) {
  const theme = useTheme();
  const radiusWithBorder = useMemo(() => size / 2, [size]);
  const radiusWithoutBorder = useMemo(
    () => radiusWithBorder - thickness / 2,
    [radiusWithBorder, thickness]
  );

  const [isFirstRenderSincePause, setIsFirstRenderSincePause] = useState(true);

  useEffect(() => {
    if (!pause) {
      setIsFirstRenderSincePause(false);
    }
  }, [pause]);

  useEffect(() => {
    if (pause) {
      setIsFirstRenderSincePause(true);
    }
  }, [pause]);

  return (
    <div
      style={
        {
          position: "relative",
          margin: "auto",
          height: size,
          width: size,
          textAlign: "center",
        } as React.CSSProperties
      }
    >
      <svg
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: `${size}px`,
          height: `${size}px`,
          rotate: "-90deg",
        }}
      >
        <circle
          r={radiusWithoutBorder}
          cx={radiusWithBorder}
          cy={radiusWithBorder}
          style={{
            stroke: getColorFromTheme(theme, color),
            strokeWidth: thickness,
            fill: "none",
            strokeDasharray: DASH_ARRAY,
            strokeDashoffset: isFirstRenderSincePause
              ? (progressInPercentage / 100) * DASH_ARRAY
              : (expectedNextProgressInPercentage / 100) * DASH_ARRAY,
            transitionProperty: "stroke-dashoffset, opacity, color",
            transitionDuration: `${pause ? 250 : 1000}ms`,
            transitionTimingFunction: pause ? "ease" : "linear",
            opacity: Math.max(
              expectedNextTimeLeftInPercentage /
                DECREASE_OPACITY_PERCENTAGE_BREAKPOINT
            ),
          }}
        ></circle>
      </svg>
    </div>
  );
}
