import AnnouncementIcon from "@mui/icons-material/Announcement";
import Calendar from "@mui/icons-material/CalendarMonth";
import Cancel from "@mui/icons-material/Cancel";
import ChangeCircle from "@mui/icons-material/ChangeCircle";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Close from "@mui/icons-material/Close";
import Contrast from "@mui/icons-material/Contrast";
import DarkMode from "@mui/icons-material/DarkMode";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import Email from "@mui/icons-material/Email";
import EventAvailable from "@mui/icons-material/EventAvailable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Fingerprint from "@mui/icons-material/Fingerprint";
import Flag from "@mui/icons-material/Flag";
import TwoPeople from "@mui/icons-material/Group";
import Group from "@mui/icons-material/Groups";
import Help from "@mui/icons-material/Help";
import Home from "@mui/icons-material/Home";
import Info from "@mui/icons-material/Info";
import LightMode from "@mui/icons-material/LightMode";
import LockReset from "@mui/icons-material/LockReset";
import Login from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/icons-material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import Notifications from "@mui/icons-material/Notifications";
import Person from "@mui/icons-material/Person";
import RateReview from "@mui/icons-material/RateReview";
import Save from "@mui/icons-material/Save";
import Search from "@mui/icons-material/Search";
import Settings from "@mui/icons-material/Settings";
import SettingsPhone from "@mui/icons-material/SettingsPhone";
import Store from "@mui/icons-material/Store";
import Tour from "@mui/icons-material/Tour";
import Translate from "@mui/icons-material/Translate";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

export type Icon = OverridableComponent<SvgIconTypeMap<object, "svg">> & {
  muiName: string;
};

const icons = {
  Accept: CheckCircle,
  Announcement: AnnouncementIcon,
  Calendar,
  Cancel: DoDisturbOnIcon,
  Change: ChangeCircle,
  Close,
  Contrast,
  DarkMode,
  EditPassword: LockReset,
  Email,
  ExpandLess,
  ExpandMore,
  Feedback: RateReview,
  Fingerprint,
  Flag,
  Group,
  Home,
  Info,
  LightMode,
  Login,
  Logout,
  Menu,
  MoreVert,
  Notifications,
  NotificationSettings: SettingsPhone,
  Person,
  Reject: Cancel,
  Reservation: EventAvailable,
  Save,
  Search,
  Settings,
  Tour,
  Translate,
  TwoPeople,
  Unknown: Help,
  Venue: Store,
  Visibility,
  VisibilityOff,
} as const;

export default icons;
