import { Product, Venue } from "@/graphql/operations";
import { ApolloError } from "@apollo/client";
import { createContext } from "react";

type VenueContext = {
  error: ApolloError | undefined;
  loading: boolean;
  productMap: Map<string, Product>;
  venues: Venue[];
  venueMap: Map<string, Venue>;
  hasMultipleVenues: boolean;
};

export const VenueContext = createContext<VenueContext | null>(null);
