export const errors = {
  unauthorized: "unauthorized",
  invalidCredentials: "invalidCredentials",
  invalidPassword: "invalidPassword",
  missingCredentials: "missingCredentials",
  graceTimerExpired: "graceTimerExpired",
  passwordRequestUsed: "passwordRequestUsed",
  passwordRequestExpired: "passwordRequestExpired",
  passwordRequestFailed: "passwordRequestFailed",
} as const;

export type VenuePortalError = (typeof errors)[keyof typeof errors];
