import { VenueReservation } from "@/graphql/operations";
import { ReservationsContext } from "@/providers/ReservationsProvider/ReservationsContext";
import { isPast, parse } from "date-fns";
import { useContext } from "react";

const getReservationDateTime = (reservation: VenueReservation) =>
  parse(
    `${reservation.group?.date}-${reservation.group?.time}`,
    "yyyy-MM-dd-HH:mm:ss",
    new Date()
  );

export const reservationHasPassed = (reservation: VenueReservation) =>
  isPast(getReservationDateTime(reservation));

export const isReservationUpdate = (reservation: VenueReservation) =>
  !(reservation.answer || !reservation.originalReservation?.answer);

export const isPendingReservationUpdate = (reservation: VenueReservation) =>
  !reservationHasPassed(reservation) && isReservationUpdate(reservation);

export const reservationStatus = {
  accepted: "ACCEPTED",
  cancelled: "CANCELLED",
  rejected: "REJECTED",
  pending: "PENDING",
} as const;

type ReservationStatus =
  (typeof reservationStatus)[keyof typeof reservationStatus];

export const getReservationStatus = (
  reservation: VenueReservation
): ReservationStatus => {
  if (reservation.cancellation) return reservationStatus.cancelled;
  if (!reservation.answer) return reservationStatus.pending;
  return {
    ACCEPTED: reservationStatus.accepted,
    NOT_ACCEPTED: reservationStatus.rejected,
  }[reservation.answer];
};

export const getReservationStatusLowerCase = (reservation: VenueReservation) =>
  getReservationStatus(
    reservation
  ).toLocaleLowerCase() as Lowercase<ReservationStatus>;

export default function useReservation() {
  const context = useContext(ReservationsContext);
  if (!context) {
    throw new Error(
      "ReservationsContext must be used within a ReservationsProvider"
    );
  }
  return context;
}
