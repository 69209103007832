import { errors } from "@shared/errors";
import { PartialI18nLocale } from "../types";

export const jaLocale: PartialI18nLocale = {
  identity: { name: "日本語", flag: "🇯🇵" },

  command: {
    accept: "受け入れる",
    acceptWhat: "{{what}}を受け入れる",
    closeWhat: "{{what}}を閉じる",
    confirmWhat: "{{what}}を確認",
    editWhat: "{{what}}を変更する",
    logIn: "ログイン",
    logOut: "ログアウト",
    openWhat: "{{what}}を開く",
    reject: "拒否",
    rejectWhat: "{{what}}の拒否",
    resetWhat: "{{what}}のリセット",
    requestWhat: "{{what}}をリクエスト",
    save: "保存",
    search: "検索",
    selectWhat: "{{what}}を選択",
    sendWhat: "{{what}}の送信",
    sendingWhat: "{{what}}の送信中",
    setWhat: "{{what}}の設定",
    toggleWhat: "{{what}}の切り替え",
  },

  date: {
    date: "{{year}}年{{month}}月{{day}}日",
    Fri: "金",
    Mon: "月",
    Sat: "土",
    Sun: "日",
    Thu: "木",
    Tue: "火",
    Wed: "水",
  },

  error: {
    [errors.invalidCredentials]: "エラー　無効です",
    [errors.invalidPassword]: "エラー　無効なパスワード",
    [errors.missingCredentials]: "エラー　資格不足です",
    [errors.unauthorized]: "エラー　アクセス権限がありません",
    [errors.passwordRequestExpired]: "このパスワードリクエストは無効です。",
    [errors.passwordRequestUsed]:
      "このパスワード要求はすでに使用されています。新しいパスワードを再度設定してください。",
    [errors.passwordRequestFailed]:
      "このパスワード要求は処理できませんでした。新しいパスワードを再度設定してください。",
  },

  messages: {
    feedbackExplanation:
      "ベニューポータルに関するフィードバックをお聞かせください。これまでの操作状況はいかがでしょうか？どの点が難しい・使いにくいとお考えでしょうか？もしフィードバックを頂けましたら、引き続きシステムの改善に努めて参ります。",
    feedbackGratitude: "ご協力ありがとうございます。",
    inquiryExplanation:
      "予約に関してのお問い合わせでしょうか？弊社にお問い合わせ頂けましたらできる限り早くご回答いたします。",
  },

  notification: {
    feedback: {
      failedToSendFeedback: "フィードバックの送信失敗",
      sentFeedback: "フィードバックの送信完了",
    },
    inquiry: {
      failedToSendInquiry: "問い合わせの送信失敗",
      sentInquiry: "問い合わせの送信完了",
    },
    login: {
      failedToLogin: "ログインの失敗",
      loggedIn: "ログイン",
      loggedOut: "ログアウト",
    },
    password: {
      failedToSavePassword: "パスワードの保存失敗",
      failedToSendPasswordResetEmail: "パスワードの送信失敗",
      savedPassword: "保存されたパスワード",
      sentPasswordResetEmail: "パスワードをメールで送りました",
    },
    reservation: {
      acceptedReservation: "予約受け入れ完了",
      failedToUpdateReservation: "予約変更不可",
      rejectedReservation: "予約拒否",
      resetReservation: "予約のリセット",
    },
  },

  page: {
    feedback: "フィードバック",
    home: "ホーム",
    settings: "設定",
  },

  passwordValidation: {
    confirmationPasswordMismatch: "パスワードは2回入力をお願いします。",
    passwordMinimumLength: "パスワードは8文字以上で設定をお願いします。",
    passwordNeedsLowercase:
      "最低1文字は半角小文字(アルファベット）でお願いします。",
    passwordNeedsSpecialCharacter:
      "最低1文字は特殊文字（! / ? 等）でお願いします。",
    passwordNeedsUppercase:
      "最低1文字は半角大文字(アルファベット）でお願いします。",
    passwordShould: "パスワードに関して",
  },

  reservation: {
    accepted: "承諾済み",
    answeredAt: "{{answer}}日",
    answeredAtWhen: "{{- when}}/{{answer}}",
    answeredAtWhenByWho: "{{- when}}/{{who}}によって{{answer}}",
    endOfReservations: "予約はここまでです。",
    guest: "ゲスト人数",
    guide: "ガイド人数",
    noGuideAssigned: "ガイドが割り当てられていません",
    noReservations: "予約リクエストはありません",
    rejected: "拒否済み",
    unanswered: "未回答",
    unconfirmedWhat: "{{what}}未回答",
    updateRequestedAt: "最終更新日",
    voiceover: {
      reservationDateTimeSummary: "予約日時: {{date}} {{time}}",
      reservationInfo:
        "人数: {{numGuests}} {{guestLabel}}, {{numGuides}} {{guideLabel}}, ガイド名: {{guideName}}",
    },
  },

  settings: {
    mode: { dark: "ダークモード", light: "ライトモード" },
    languageSettings: "言語設定",
    notificationSettings: "通知設定",
    password: {
      current: "現在のパスワード",
      forgot: "パスワードを忘れた",
      new: "新しいパスワード",
    },
    receiveEmailNotifications: "メールの通知設定を受け取る",
  },

  nouns: {
    all: "全て",
    contact: "連絡先",
    email: "メール",
    feedback: "フィードバック",
    from: "から",
    inquiry: "問い合わせ",
    language: "言語",
    password: "パスワード",
    profile: "プロフィール",
    MagicalTrip: "マジカルトリップ",
    reservation: "予約",
    searchMenu: "予約検索",
    siteName: "ベニューポータル",
    theme: "テーマ",
    tour: "ツアー",
    until: "まで",
    venue: "ベニュー",
  },
};
