import { getEnvVariable } from "@/tools/environment/envVariables";
import { getJwtAuthToken } from "@/tools/jwtToken/jwtToken";
import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";
import { cache } from "./cache/cache";

export const createApolloClient = () => {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: getJwtAuthToken(),
        "Apollo-Require-Preflight": "true",
      },
    });
    return forward(operation);
  });

  const httpLink = createHttpLink({
    uri: `${getEnvVariable("VITE_VENUE_PORTAL_API_URL")}/graphql`,
  });

  return new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache,
  });
};
