import "@/setup/analytics";

import "@/i18n/i18n";
import "@/setup/sentry";

import Router from "@/Router/Router";
import Notifications from "@/components/NotificationBar/Notifications";
import { CssBaseline } from "@mui/material";
import ComposedProvider from "../providers/ComposedProvider";
import "./App.css";

// Please reflect changes to the App and the ComposedProvider in the MockEnvironment
// Tests will that tests will have the same setup
// venue-portal/webapp/src/tools/testing/MockEnvironment.tsx
function App() {
  return (
    <ComposedProvider>
      <CssBaseline enableColorScheme />
      <Router />
      <Notifications />
    </ComposedProvider>
  );
}

export default App;

// redeploy!
