import { Typography, TypographyProps } from "@mui/material";

type MaxLinesTypographyProps = TypographyProps & {
  maxLines: number;
};
export default function MaxLinesTypography({
  maxLines,
  sx,
  ...props
}: MaxLinesTypographyProps) {
  return (
    <Typography
      {...props}
      sx={{
        ...sx,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: maxLines,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
  );
}
