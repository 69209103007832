import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";

const dayRadius = 18;

type StyledDayProps = PickersDayProps<Date> & {
  isStartOfWeekOrMonth: boolean;
  isEndOfWeekOrMonth: boolean;
  isFrom: boolean;
  isUntil: boolean;
  isWithinRange: boolean;
  isCurrentMonth: boolean;
};

function StyledDay({
  isStartOfWeekOrMonth,
  isEndOfWeekOrMonth,
  isFrom,
  isUntil,
  isWithinRange,
  isCurrentMonth,
  ...props
}: StyledDayProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...(isWithinRange &&
          isCurrentMonth && {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          }),
        ...((isStartOfWeekOrMonth || isFrom) && {
          clipPath: `inset(0 -100% 0 CALC(50% - ${dayRadius}px) round ${dayRadius}px)`,
        }),
        ...((isEndOfWeekOrMonth || isUntil) && {
          clipPath: `inset(0 CALC(50% - ${dayRadius}px) 0 -100% round ${dayRadius}px)`,
        }),
        ...(isFrom &&
          isUntil && {
            clipPath: `circle(${dayRadius}px)`,
          }),
      }}
    >
      <PickersDay
        {...props}
        sx={{
          border: "2px solid transparent",
          backgroundColor: "transparent",
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      />
    </Box>
  );
}

type DayProps = PickersDayProps<Date> & {
  from: Date | null;
  until: Date | null;
  hoveredDay?: Date | null;
  currentMonth: Date;
};

export function Day({ day, from, until, currentMonth, ...rest }: DayProps) {
  const start = from;
  const end = until ?? from;

  const isSelected =
    (from &&
      isWithinInterval(day, {
        start: startOfDay(from),
        end: endOfDay(until ?? from),
      })) ??
    false;

  const isFrom = isSameDay(day, start ?? new Date(0));
  const isUntil = isSameDay(day, end ?? new Date(0));

  return (
    <StyledDay
      {...rest}
      day={day}
      disableMargin
      selected={isFrom || isUntil}
      isStartOfWeekOrMonth={
        isSameDay(day, startOfWeek(day)) || isSameDay(day, startOfMonth(day))
      }
      isEndOfWeekOrMonth={
        isSameDay(day, endOfWeek(day)) || isSameDay(day, endOfMonth(day))
      }
      isFrom={isFrom}
      isUntil={isUntil}
      isWithinRange={isSelected}
      isCurrentMonth={isSameMonth(day, currentMonth)}
    />
  );
}
