import { useCallback, useEffect, useMemo, useState } from "react";

type useTimerProps = {
  pause?: boolean;
  start?: number;
  tickingDelayMs?: number;
} & (
  | {
      end: number;
    }
  | {
      duration: number;
    }
);

type CalculateTimeLeftArgs = {
  duration?: number;
  end?: number;
  pointInTime?: number;
};
export default function useTimer({
  start,
  pause,
  tickingDelayMs = 1000,
  ...props
}: React.PropsWithChildren<useTimerProps>) {
  const duration = useMemo(
    () =>
      "end" in props
        ? calculateTimeLeft({ end: props.end, pointInTime: start })
        : props.duration,
    [start, props]
  );

  const end = useMemo(
    () => (start ? start + duration : undefined),
    [start, duration]
  );

  const getTimeLeft = useCallback(
    () => calculateTimeLeft({ end, duration }),
    [end, duration]
  );
  const getExpectedNextTimeLeft = useCallback(
    () =>
      calculateTimeLeft({
        end,
        duration,
        pointInTime: Date.now() + tickingDelayMs,
      }),
    [end, duration, tickingDelayMs]
  );

  const [timeLeft, setTimeLeft] = useState(getTimeLeft());
  const [expectedNextTimeLeft, setExpectedNextTimeLeft] = useState(
    getExpectedNextTimeLeft()
  );
  const [prevTimeLeft, setPrevTimeLeft] = useState(timeLeft);

  const updateTimeLeft = useCallback(() => {
    setTimeLeft(getTimeLeft());
    setExpectedNextTimeLeft(getExpectedNextTimeLeft());
  }, [getTimeLeft, getExpectedNextTimeLeft]);

  useEffect(updateTimeLeft, [start, end, duration, updateTimeLeft]);

  useEffect(() => {
    if (timeLeft <= 0 || pause) return;

    const timer = setInterval(() => {
      setPrevTimeLeft(timeLeft);
      updateTimeLeft();
    }, tickingDelayMs);

    return () => clearInterval(timer);
  }, [timeLeft, end, pause, duration, tickingDelayMs, updateTimeLeft]);

  const { progressInPercentage, timeLeftInPercentage } = useMemo(
    () => getTimeLeftInPercentage(timeLeft, duration),
    [timeLeft, duration]
  );
  const {
    progressInPercentage: expectedNextProgressInPercentage,
    timeLeftInPercentage: expectedNextTimeLeftInPercentage,
  } = useMemo(
    () => getTimeLeftInPercentage(expectedNextTimeLeft, duration),
    [expectedNextTimeLeft, duration]
  );

  const timeUp = useMemo(() => timeLeft <= 0, [timeLeft]);

  return {
    duration,
    end,
    expectedNextProgressInPercentage,
    expectedNextTimeLeft,
    expectedNextTimeLeftInPercentage,
    prevTimeLeft,
    progressInPercentage,
    timeLeft,
    timeLeftInPercentage,
    timeUp,
  };
}

const calculateTimeLeft = ({
  end,
  duration,
  pointInTime,
}: CalculateTimeLeftArgs) =>
  Math.max(0, (end ?? Date.now() + duration!) - (pointInTime ?? Date.now()));

const getTimeLeftInPercentage = (timeLeft: number, duration: number) => {
  const timeLeftInPercentage = (100 * timeLeft) / duration;
  const progressInPercentage = 100 - timeLeftInPercentage;
  return { timeLeftInPercentage, progressInPercentage };
};
