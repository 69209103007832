import { UserContext } from "@/providers/UserProvider/UserProvider";
import { useContext } from "react";

export default function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("UserContext must be used within a UserProvider");
  }

  return context;
}
