import PendingReservationsBadge from "@/components/PendingReservationsCounter/PendingReservationsBadge";
import { zIndex } from "@/constants";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";
import { Badge, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoWithEnvironment from "../../LogoWithEnvironment";
import useVenuePortalUserOptions from "../../VenuePortalUserOptionsAutocomplete/useVenuePortalUserOptions";
import DrawerButton from "./components/DrawerButton";
import ImpersonationDrawer from "./components/ImpersonationDrawer";
import NavigationDrawer from "./components/NavigationDrawer";
import SearchDrawer from "./components/SearchDrawer";

type DrawerType = "navigation" | "search";

export default function MobileHeader() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState<null | DrawerType>(null);
  const closeDrawer = useCallback(() => setOpenDrawer(null), []);
  const { user, isMtUser } = useUser();

  const { venuePortalUserOptionMap } = useVenuePortalUserOptions();

  const impersonatedUser = venuePortalUserOptionMap.get(user.userId ?? "");
  const firstLetterOfEmail = impersonatedUser?.label?.[0] ?? null;

  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: isLightMode ? "primary" : "background.paper",
        boxShadow: 0,
        zIndex: zIndex.high,
      }}
      enableColorOnDark={true}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Stack
          direction="row"
          gap={1}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          alignItems="center"
        >
          <LogoWithEnvironment />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {[
            {
              name: "impersonation",
              Icon: (
                <Badge badgeContent={firstLetterOfEmail} color="primary">
                  <icons.Fingerprint sx={{ color: "#FFFFFF" }} />
                </Badge>
              ),
              Drawer: ImpersonationDrawer,
              hide: !isMtUser,
            },
            {
              name: "search",
              Icon: <icons.Search sx={{ color: "#FFFFFF" }} />,
              Drawer: SearchDrawer,
            },
            {
              name: "navigation",
              Icon: (
                <PendingReservationsBadge>
                  <icons.Menu sx={{ color: "#FFFFFF" }} />
                </PendingReservationsBadge>
              ),
              Drawer: NavigationDrawer,
            },
          ]
            .filter(({ hide }) => !hide)
            .map(({ name, Icon, Drawer }) => (
              <DrawerButton
                key={name}
                open={openDrawer === name}
                handleClose={closeDrawer}
                handleOpen={() => setOpenDrawer(name as DrawerType)}
                Icon={Icon}
                Drawer={Drawer}
              />
            ))}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
