import { getEnvVariable } from "./envVariables";

type Environment = "production" | "staging" | "local";

const envEnvironment = () =>
  ((getEnvVariable("VITE_ENVIRONMENT") ?? "loca") as string)
    .toLowerCase()
    .slice(0, 4);

export const getEnvironment = () =>
  (({
    prod: "production",
    stag: "staging",
    loca: "local",
  }[envEnvironment()] ?? "local") as Environment);

export const isProductionEnvironment = () => getEnvironment() === "production";

export const isStagingEnvironment = () => getEnvironment() === "staging";

export const isLocalEnvironment = () =>
  !(isProductionEnvironment() || isStagingEnvironment());
