import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useState } from "react";

export type LoadingButtonProps = ButtonProps;
export default function LoadingButton(props: LoadingButtonProps) {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setLoading(true);
      try {
        if (props.onClick) await Promise.resolve(props.onClick(event));
      } finally {
        setLoading(false);
      }
    },
    [props]
  );

  return (
    <Button
      {...props}
      onClick={onClick}
      disabled={props.disabled || loading}
      endIcon={
        loading ? <CircularProgress size={18} color="inherit" /> : props.endIcon
      }
    />
  );
}
