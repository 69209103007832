import LoadingButton from "@/components/LoadingButton/LoadingButton";
import DefaultTimerButton from "@/components/TimerButton/DefaultTimerButton/DefaultTimerButton";
import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import useReservation, {
  getReservationStatusLowerCase,
} from "@/hooks/useReservation";
import { unixTimeToComponents } from "@/tools/date/conversion";
import { useTheme } from "@mui/material";
import { resetReservationGraceTimeMilliseconds } from "@shared/constants";

type ResetReservationButtonProps = {
  reservation: VenueReservation;
  disabled?: boolean;
};
export default function ResetReservationButton({
  reservation,
  disabled,
}: ResetReservationButtonProps) {
  const { t } = useLocalization();
  const { resetReservation } = useReservation();
  const theme = useTheme();

  return (
    <DefaultTimerButton
      sx={{
        fontWeight: theme.custom.reservationSummaryFontWeight,
        fontSize: "16px",
      }}
      start={
        reservation.answer
          ? Number(reservation.reservationAnswerReceived)
          : undefined
      }
      disabled={disabled}
      disableTimer={disabled}
      pause={!reservation.answer}
      fullWidth
      variant="contained"
      duration={resetReservationGraceTimeMilliseconds / 2}
      onClick={() => resetReservation(reservation.id)}
      CustomButton={LoadingButton}
      thickness={4}
      aria-bales={t("command:resetWhat", {
        interpolation: {
          what: t("nouns:reservation"),
        },
      })}
      timeUpComponent={
        reservation.answer
          ? `${t(
              `reservation:${
                reservation.answeredBy
                  ? "answeredAtWhenByWho"
                  : "answeredAtWhen"
              }`,
              {
                interpolation: {
                  answer: t(
                    `reservation:${getReservationStatusLowerCase(reservation)}`
                  ),
                  when: t("date:date", {
                    interpolation: unixTimeToComponents(
                      reservation.reservationAnswerReceived!
                    ),
                  }),
                  who:
                    reservation.answeredBy === "MagicalTrip"
                      ? t("nouns:MagicalTrip")
                      : reservation.answeredBy,
                },
              }
            )}`
          : t("reservation:unanswered")
      }
    >
      {t("command:resetWhat", {
        interpolation: {
          what: t("nouns:reservation"),
        },
      })}
    </DefaultTimerButton>
  );
}
