import useLocalization from "@/hooks/useLocalization";
import { Localization, localizations } from "@/i18n/types";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function LocalizationSettings() {
  const { localization, setLocalization, t } = useLocalization();

  const handleChange = (event: SelectChangeEvent) => {
    setLocalization(event.target.value as Localization);
  };

  return (
    <Select
      variant="standard"
      fullWidth
      value={localization}
      onChange={handleChange}
    >
      {localizations.map((language) => (
        <MenuItem value={language} key={language}>
          {t("identity:name", { localization: language })}
        </MenuItem>
      ))}
    </Select>
  );
}
