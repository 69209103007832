import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import {
  dateStringToComponents,
  dateToShortDay,
} from "@/tools/date/conversion";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { PrimaryAndSecondaryElement } from "./PrimaryAndSecondaryElement";

type DateTimeSummaryProps = { reservation: VenueReservation };
export default function DateTimeSummary({ reservation }: DateTimeSummaryProps) {
  const { t } = useLocalization();

  const reservationDateTimeSummary = t(
    "reservation:voiceover.reservationDateTimeSummary",
    {
      interpolation: {
        date: reservation.group!.date,
        time: reservation.reservationTime?.slice(0, 5),
      },
    }
  );

  return (
    <PrimaryAndSecondaryElement
      sx={{ flexGrow: 0 }}
      aria-label={reservationDateTimeSummary}
      primary={t("date:date", {
        interpolation: dateStringToComponents(reservation.group!.date),
      })}
      secondary={
        <Stack direction="row" justifyContent="space-between">
          <Box>{t(`date:${dateToShortDay(reservation.group!.date)}`)}</Box>
          {reservation.reservationTime?.slice(0, 5)}
        </Stack>
      }
    />
  );
}
