import { zIndex } from "@/constants";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DateRangeCalendar } from "./Calendar";
import { DateRangeTextField } from "./DateRangeTextField";
import { FromOrUntilFocus } from "./types";

export type DateRangePickerProps = {
  from: Date | null;
  setFrom: Dispatch<SetStateAction<Date | null>>;
  until: Date | null;
  setUntil: Dispatch<SetStateAction<Date | null>>;
};
export default function DateRangePicker({ ...props }: DateRangePickerProps) {
  const [focus, setFocus] = useState<FromOrUntilFocus>(null);

  const closeCalendar = useCallback(() => setFocus(null), [setFocus]);
  const isCalendarOpen = useMemo(() => !!focus, [focus]);
  const fromTextFieldRef = useRef<HTMLDivElement | null>(null);
  const untilTextFieldRef = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(368);

  useEffect(() => {
    const handleResize = () => {
      if (fromTextFieldRef.current && untilTextFieldRef.current)
        setWidth(() => {
          const leftX = fromTextFieldRef.current!.getBoundingClientRect().left;
          const rightX =
            untilTextFieldRef.current!.getBoundingClientRect().right;
          return rightX - leftX;
        });
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setFocus(null)}>
      <Box>
        <Stack direction="row" alignItems="center" gap={1}>
          <Box ref={fromTextFieldRef}>
            <DateRangeTextField
              date={props.from}
              fromOrUntil="from"
              focus={focus}
              setFocus={setFocus}
            />
          </Box>
          -
          <Box ref={untilTextFieldRef}>
            <DateRangeTextField
              date={props.until}
              fromOrUntil="until"
              focus={focus}
              setFocus={setFocus}
            />
          </Box>
        </Stack>
        <Popper
          open={isCalendarOpen}
          anchorEl={fromTextFieldRef.current}
          placement="bottom-start"
          sx={{ zIndex: zIndex.high }}
        >
          <DateRangeCalendar
            {...props}
            closeCalendar={closeCalendar}
            focus={focus}
            setFocus={setFocus}
            width={width}
          />
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
