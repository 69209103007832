import useUser from "@/hooks/useUser";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Localization } from "../../i18n/types";
import { VenueContext } from "./VenueContext";
import {
  GET_USER_VENUES_QUERY,
  GetUserVenuesInput,
  GetUserVenuesResponse,
} from "./queries/GetUserVenuesQuery";

export type VenueContextProps = {
  localization?: Localization;
};

export default function VenueProvider({
  children,
}: React.PropsWithChildren<VenueContextProps>) {
  const {
    user: { userId },
  } = useUser();
  const { data, loading, error } = useQuery<
    GetUserVenuesResponse,
    GetUserVenuesInput
  >(GET_USER_VENUES_QUERY, {
    variables: {
      venuePortalUserId: userId ?? "",
    },
    onError(error) {
      console.error(error.message);
    },
    skip: !userId,
  });

  const venues = useMemo(() => data?.venuePortalUser.venues ?? [], [data]);
  const venueMap = useMemo(() => generateMap(venues), [venues]);
  const productMap = useMemo(
    () => generateMap(venues.flatMap(({ products }) => products ?? [])),
    [venues]
  );

  const hasMultipleVenues = useMemo(() => venues.length > 1, [venues]);

  return (
    <VenueContext.Provider
      value={{
        error,
        loading,
        productMap,
        venueMap,
        venues,
        hasMultipleVenues,
      }}
    >
      {children}
    </VenueContext.Provider>
  );
}

function generateMap<T extends { id: string }>(objects: T[]) {
  return new Map(objects.map((object) => [object.id, object]));
}
