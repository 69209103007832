import useColorMode from "@/hooks/useColorMode/useColorMode";
import useLocalization from "@/hooks/useLocalization";
import icons from "@/tools/icons";
import Stack from "@mui/material/Stack";
import { useMemo, useState } from "react";
import ColorModeSettings from "./ColorModeSettings";
import EditPasswordSettings from "./EditPasswordSettings";
import LocalizationSettings from "./LocalizationSettings";
import NotificationSettings from "./NotificationSettings";
import { SettingsMenuItem } from "./SettingsMenuItem";

export default function SettingsPage() {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleClickAccordion = (index: number) =>
    setExpandedIndex((expandedIndex) =>
      expandedIndex === index ? null : index
    );

  const { t } = useLocalization();

  const { colorMode } = useColorMode();

  const settingsMenuItems = useMemo(
    () => [
      {
        Content: NotificationSettings,
        menuItemProps: {
          Icon: icons.NotificationSettings,
          label: t("settings:notificationSettings"),
          ariaLabel: t("settings:notificationSettings"),
        },
      },
      {
        Content: LocalizationSettings,
        menuItemProps: {
          Icon: icons.Translate,
          label: t("nouns:language"),
          preview: t("identity:name"),
          ariaLabel: t("settings:languageSettings"),
        },
      },
      {
        Content: ColorModeSettings,
        menuItemProps: {
          Icon: icons.Contrast,
          label: t("command:setWhat", {
            interpolation: { what: t("nouns:theme") },
          }),
          ariaLabel: t("command:setWhat", {
            interpolation: { what: t("nouns:theme") },
          }),
          preview: t(`settings:mode.${colorMode}`),
        },
      },
      {
        Content: EditPasswordSettings,
        contentProps: {
          onSuccess: () => {
            if (expandedIndex === 3) setExpandedIndex(null);
          },
        },
        menuItemProps: {
          Icon: icons.EditPassword,
          label: t("command:editWhat", {
            interpolation: {
              what: t("nouns:password"),
            },
          }),
          ariaLabel: t("command:editWhat", {
            interpolation: {
              what: t("nouns:password"),
            },
          }),
        },
      },
    ],
    [colorMode, expandedIndex, t]
  );

  return (
    <Stack>
      {settingsMenuItems.map(
        ({ Content, menuItemProps, contentProps }, index) => (
          <SettingsMenuItem
            key={index}
            {...menuItemProps}
            handleClickAccordion={() => handleClickAccordion(index)}
            expanded={expandedIndex === index}
            aria-label={menuItemProps.ariaLabel}
            aria-expanded={expandedIndex === index}
          >
            <Content {...contentProps} />
          </SettingsMenuItem>
        )
      )}
    </Stack>
  );
}
