import { Product, Venue, VenueReservation } from "@/graphql/operations";
import {
  ApolloError,
  LazyQueryExecFunction,
  QueryResult,
} from "@apollo/client";
import { Dispatch, SetStateAction, createContext } from "react";
import {
  GetVenueReservationQueryInput,
  GetVenueReservationQueryResponse,
} from "./queries/GetVenueReservationQuery";

type ReservationsContext = {
  acceptReservation: (reservationId: string) => void;
  canFetchMoreReservations: boolean;
  error: ApolloError | undefined;
  fetchMoreReservations: () => Promise<void>;
  fetchReservation: LazyQueryExecFunction<
    GetVenueReservationQueryResponse,
    GetVenueReservationQueryInput
  >;
  fetchReservationResult: QueryResult<
    GetVenueReservationQueryResponse,
    GetVenueReservationQueryInput
  >;
  fetchReservations: () => Promise<void>;
  from: Date | null;
  hasSearchParams: boolean;
  loading: boolean;
  pendingReservationsCount: number;
  productId: Product["id"] | null;
  rejectReservation: (reservationId: string) => void;
  reservations: VenueReservation[];
  resetReservation: (reservationId: string) => void;
  resetSearch: () => Promise<void>;
  setFrom: Dispatch<SetStateAction<Date | null>>;
  setProductId: Dispatch<SetStateAction<Product["id"] | null>>;
  setUntil: Dispatch<SetStateAction<Date | null>>;
  setVenueId: Dispatch<SetStateAction<Venue["id"] | null>>;
  totalReservations: number;
  until: Date | null;
  venueId: Venue["id"] | null;
};

export const ReservationsContext = createContext<ReservationsContext | null>(
  null
);
