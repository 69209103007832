import useLocalization from "@/hooks/useLocalization";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

type FooterProps = {
  canFetchMoreReservations: boolean;
  totalReservations: number;
  loading: boolean;
};
export default function Footer({
  canFetchMoreReservations,
  totalReservations,
  loading,
}: FooterProps) {
  const { t } = useLocalization();
  return (
    <Box sx={{ textAlign: "center" }}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Typography>
          {totalReservations === 0
            ? t("reservation:noReservations")
            : canFetchMoreReservations
            ? ""
            : t("reservation:endOfReservations")}
        </Typography>
      )}
    </Box>
  );
}
