import { format } from "date-fns";

export const convertLocalToUtc = (date: Date | null) =>
  date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : date;

const unixTimeToDate = (unixTime: string | number) =>
  new Date(Number(unixTime));

export const formatUnixTimeToDate = (unixTime: string | number) =>
  format(unixTimeToDate(unixTime), "yyyy-MM-dd HH:mm");

export const dateToComponents = (date: Date) => ({
  year: date.getFullYear(),
  month: String(date.getMonth() + 1).padStart(2, "0"),
  day: String(date.getDate()).padStart(2, "0"),
});

export const dateStringToComponents = (dateString: string) => {
  const [year, month, day] = dateString.split("-").slice(0, 3);
  return {
    year,
    month,
    day,
  };
};

export const unixTimeToComponents = (unixTime: string | number) =>
  dateToComponents(unixTimeToDate(unixTime));

export const dateToShortDay = (date: Date | string) =>
  format(date, "EEE") as "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
