import Box from "@mui/material/Box";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";

type SettingIconProps = {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
};

export function SettingIcon({ Icon }: SettingIconProps) {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "50%",
        width: 48,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon fontSize="large" sx={{ color: "primary.contrastText" }} />
    </Box>
  );
}
