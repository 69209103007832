import { VenuePortalUser } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type GetVenuePortalUserSettingsInput = {
  venuePortalUserId: string;
};
export type GetVenuePortalUserSettingsResponse = {
  venuePortalUser: Pick<VenuePortalUser, "id" | "settings">;
};

export const GET_VENUE_PORTAL_USER_SETTINGS_QUERY = gql`
  query GetVenuePortalUserSettings($venuePortalUserId: ID) {
    venuePortalUser(id: $venuePortalUserId) {
      id

      settings {
        theme
        iso639_1
        receiveReservationEmails
      }
    }
  }
`;
