import MaxLinesTypography from "@/components/MaxLinesTypography/MaxLinesTypography";
import useTimer from "@/hooks/useTimer";
import { getColorFromTheme } from "@/tools/theme/getColor";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { TimerButtonProps } from "../types";

type DefaultTimerButtonProps = TimerButtonProps & {
  timeUpComponent?: ReactNode;
  CustomButton?: (props: ButtonProps) => JSX.Element;
} & Pick<ButtonProps, "fullWidth" | "variant">;

const DECREASE_OPACITY_PERCENTAGE_BREAKPOINT = 5;

export default function DefaultTimerButton({
  children,
  color,
  fullWidth,
  onClick,
  sx,
  variant,
  start,
  pause,
  thickness = 2,
  CustomButton,
  disableTimer,
  timeUpComponent,
  ...props
}: React.PropsWithChildren<DefaultTimerButtonProps>) {
  const { timeLeftInPercentage, expectedNextTimeLeftInPercentage, timeUp } =
    useTimer({
      start,
      pause,
      ...("end" in props ? { end: props.end } : { duration: props.duration }),
    });

  const disabled = useMemo(
    () => props.disabled || (!pause && timeUp),
    [props.disabled, pause, timeUp]
  );

  const [firstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const Button = useMemo(() => CustomButton ?? MuiButton, [CustomButton]);

  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Button
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={onClick}
        sx={{ ...sx, ...(color && { color: getColorFromTheme(theme, color) }) }}
        variant={variant}
      >
        <MaxLinesTypography
          sx={{
            textWrap: "balance",
          }}
          maxLines={2}
        >
          {timeUp || disableTimer ? timeUpComponent ?? children : children}
        </MaxLinesTypography>
      </Button>
      {!disableTimer && (
        <Box
          sx={{
            opacity: Math.max(
              expectedNextTimeLeftInPercentage /
                DECREASE_OPACITY_PERCENTAGE_BREAKPOINT
            ),
            position: "absolute",
            bottom: 0,
            width: `${
              firstRender
                ? timeLeftInPercentage
                : expectedNextTimeLeftInPercentage
            }%`,
            height: `${thickness}px`,
            backgroundColor: "white",
            transitionProperty: "width, opacity",
            transitionDuration: `1000ms`,
            transitionTimingFunction: "linear",
          }}
        />
      )}
    </Box>
  );
}
