import i18next from "i18next";
import { enLocale } from "./locales/en";
import { jaLocale } from "./locales/ja";
import { thLocale } from "./locales/th";
import { Localization, PartialI18nLocale } from "./types";

const resources: Record<Localization, PartialI18nLocale> = {
  en: enLocale,
  ja: jaLocale,
  th: thLocale,
};

i18next.init({
  fallbackLng: "en",
  resources,
});
