import { VenuePortalUser, VenuePortalUserSettings } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type EditVenuePortalUserSettingsMutationInput = {
  venuePortalUserId: VenuePortalUser["id"];
} & Partial<VenuePortalUserSettings>;

export type EditVenuePortalUserSettingsMutationResponse = {
  editPassword: boolean;
};
export const EDIT_VENUE_PORTAL_USER_SETTINGS_MUTATION = gql`
  mutation EditVenuePortalUserSettings(
    $venuePortalUserId: ID!
    $theme: String
    $iso639_1: String
    $receiveReservationEmails: Boolean
  ) {
    editVenuePortalUserSettings(
      venuePortalUserId: $venuePortalUserId
      theme: $theme
      iso639_1: $iso639_1
      receiveReservationEmails: $receiveReservationEmails
    )
  }
`;
