import useNotification from "@/hooks/useNotification";
import { ClickAwayListener, Collapse } from "@mui/material";
import Stack from "@mui/material/Stack";
import { TransitionGroup } from "react-transition-group";
import NotificationBar from "./NotificationBar";

export default function Notifications() {
  const { notifications, clearOldNotifications } = useNotification();

  return (
    <Stack
      spacing={1}
      alignItems="center"
      sx={{ position: "fixed", bottom: "1rem", width: "100%" }}
    >
      <ClickAwayListener
        onClickAway={() => notifications.length && clearOldNotifications(500)}
      >
        <TransitionGroup>
          {notifications.map((notification) => (
            <Collapse key={notification.id}>
              <NotificationBar notification={notification} />
            </Collapse>
          ))}
        </TransitionGroup>
      </ClickAwayListener>
    </Stack>
  );
}
