import { VenuePortalUser } from "@/graphql/operations";
import { gql } from "@apollo/client";

export type RequestPasswordInput = Partial<Pick<VenuePortalUser, "email">>;
export type RequestPasswordReponse = {
  requestPassword: boolean;
};
export const REQUEST_PASSWORD_MUTATION = gql`
  mutation RequestPasswordMutation($email: String!) {
    requestPassword(email: $email)
  }
`;
