import useReservation from "@/hooks/useReservation";
import Badge, { BadgeProps } from "@mui/material/Badge";

export default function PendingReservationsBadge(props: BadgeProps) {
  const { pendingReservationsCount } = useReservation();

  return (
    <Badge
      badgeContent={pendingReservationsCount}
      color="secondary"
      {...props}
    />
  );
}
