import useSettings from "@/hooks/useSettings";
import i18next from "i18next";
import { useCallback, useMemo } from "react";
import { I18nKey, Localization } from "../../i18n/types";
import { LocalizationContext } from "./LocalizationContext";

export type LocalizationProviderProps = {
  localization?: Localization;
};

export default function LocalizationProvider({
  children,
  ...props
}: React.PropsWithChildren<LocalizationProviderProps>) {
  const { settings, setLocalization } = useSettings();

  const localization = useMemo(
    () => props.localization ?? (settings?.iso639_1 as Localization),
    [settings?.iso639_1, props.localization]
  );

  const t = useCallback(
    (
      key: I18nKey,
      options?: { localization?: Localization; interpolation?: object }
    ) =>
      i18next.t(key, {
        lng: options?.localization ?? localization,
        ...options?.interpolation,
      }),
    [localization]
  );

  return (
    <LocalizationContext.Provider
      value={{
        localization,
        setLocalization,
        t,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
}
