import { AlertProps } from "@mui/material/Alert";
import { SnackbarProps } from "@mui/material/Snackbar";
import { useState } from "react";
import { NotificationContext } from "./NotificationContext";

export type Notification = { id: string; createdAt: Date } & Pick<
  SnackbarProps,
  "message" | "autoHideDuration"
> &
  Pick<AlertProps, "severity">;

export default function NotificationProvider({
  children,
}: React.PropsWithChildren) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
