import { NotificationContext } from "@/providers/NotificationProvider/NotificationContext";
import { Notification } from "@/providers/NotificationProvider/NotificationProvider";
import { useCallback, useContext } from "react";

const MAX_NOTIFICATIONS = 3 as const;

export default function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "NotificationContext must be used within a NotificationProvider"
    );
  }

  const { notifications, setNotifications } = context;

  const setNotification = useCallback(
    (notification: Omit<Notification, "id">) =>
      setNotifications([{ ...notification, id: crypto.randomUUID() }]),
    [setNotifications]
  );

  const addNotification = useCallback(
    (notification: Omit<Notification, "id" | "createdAt">) =>
      setNotifications((notifications) => {
        return [
          ...notifications,
          { ...notification, id: crypto.randomUUID(), createdAt: new Date() },
        ].slice(-MAX_NOTIFICATIONS);
      }),
    [setNotifications]
  );

  const addSuccessOrErrorNotification = useCallback(
    (isSuccess: boolean, successMessage: string, errorMessage: string) => {
      if (isSuccess)
        addNotification({ message: successMessage, severity: "success" });
      else addNotification({ message: errorMessage, severity: "error" });
    },
    [addNotification]
  );

  const clearNotification = useCallback(
    (id: Notification["id"]) =>
      setNotifications((notifications) =>
        notifications.filter((notification) => notification.id !== id)
      ),
    [setNotifications]
  );

  const clearNotifications = useCallback(
    () => setNotifications([]),
    [setNotifications]
  );

  const clearOldNotifications = useCallback(
    (maxAgeMs: number = 5000) =>
      setNotifications((notifications) =>
        notifications.filter(
          (notification) =>
            Date.now() - notification.createdAt.getTime() < maxAgeMs
        )
      ),
    [setNotifications]
  );

  return {
    notifications,
    setNotification,
    addNotification,
    addSuccessOrErrorNotification,
    clearNotification,
    clearNotifications,
    clearOldNotifications,
  };
}
