import useLocalization from "@/hooks/useLocalization";
import { I18nLocale } from "@/i18n/types";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

export type EditPasswordError = keyof I18nLocale["passwordValidation"];

export type EditPasswordErrorProps = {
  errors: EditPasswordError[];
};
export default function EditPasswordErrors({ errors }: EditPasswordErrorProps) {
  const { t } = useLocalization();

  const theme = useTheme();

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography fontSize={14}>
        {t("passwordValidation:passwordShould")}:
      </Typography>
      <ul style={{ textAlign: "left", marginTop: "unset" }}>
        {(
          [
            "passwordMinimumLength",
            "passwordNeedsUppercase",
            "passwordNeedsLowercase",
            "passwordNeedsSpecialCharacter",
            "confirmationPasswordMismatch",
          ] as EditPasswordError[]
        ).map((requirement) => {
          const isUnfulfilledRequirement = errors.includes(requirement);
          return (
            <li
              key={requirement}
              style={{
                opacity: isUnfulfilledRequirement ? 1 : 0.3,
                transition: "opacity 200ms ease",
              }}
            >
              <Typography
                color={
                  isUnfulfilledRequirement
                    ? theme.custom.passwordErrorColor
                    : undefined
                }
                fontSize={12}
                sx={{
                  transition: "color 200ms ease",
                }}
              >
                {t(`passwordValidation:${requirement}`)}
              </Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
}
