import { gql } from "@apollo/client";
import { VenuePortalError } from "@shared/errors";

export type LoginMutationInput = {
  email: string;
  password: string;
};
export type LoginMutationResponse = {
  login: {
    error?: VenuePortalError;
    jwtToken?: string;
  };
};
export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      error
      jwtToken
    }
  }
`;
