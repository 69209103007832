import { VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import { unixTimeToComponents } from "@/tools/date/conversion";
import icons from "@/tools/icons";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";

type PendingUpdateSummaryProps = { reservation: VenueReservation };
export default function PendingUpdateSummary({
  reservation,
}: PendingUpdateSummaryProps) {
  const { t } = useLocalization();

  return (
    <Card sx={{ padding: "8px" }}>
      <Stack direction="row" gap={2}>
        <icons.Change fontSize="large" />
        <Stack>
          <table>
            <tbody>
              <tr>
                <td>{t("reservation:updateRequestedAt")}</td>
                <td>
                  {`${t("date:date", {
                    interpolation: unixTimeToComponents(reservation.created),
                  })} ${format(new Date(reservation.created), "HH:mm")}`}
                </td>
              </tr>
              <tr>
                <td>
                  {t("reservation:answeredAt", {
                    interpolation: {
                      answer: t(
                        `reservation:${
                          {
                            ACCEPTED: "accepted",
                            NOT_ACCEPTED: "rejected",
                          }[reservation.originalReservation!.answer!] as
                            | "accepted"
                            | "rejected"
                        }`
                      ),
                    },
                  })}
                </td>
                <td>
                  {`${t("date:date", {
                    interpolation: unixTimeToComponents(
                      reservation.originalReservation!
                        .reservationAnswerReceived!
                    ),
                  })} ${format(
                    new Date(
                      Number(
                        reservation.originalReservation!
                          .reservationAnswerReceived!
                      )
                    ),
                    "HH:mm"
                  )}`}
                </td>
              </tr>
            </tbody>
          </table>
        </Stack>
      </Stack>
    </Card>
  );
}
