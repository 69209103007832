import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import { ReactNode } from "react";

type PrimaryAndSecondaryElementProps = {
  primary: ReactNode;
  secondary: ReactNode;
} & StackProps;

export function PrimaryAndSecondaryElement({
  primary,
  secondary,
  ...stackProps
}: PrimaryAndSecondaryElementProps) {
  const theme = useTheme();
  return (
    <Stack direction="column" {...stackProps}>
      <Box sx={{ textWrap: "nowrap" }}>{primary}</Box>
      <Box
        sx={{
          opacity: 0.6,
          fontSize: "16px",
          fontWeight: theme.custom.reservationSummaryFontWeight,
        }}
      >
        {secondary}
      </Box>
    </Stack>
  );
}
