import { Dispatch, SetStateAction, createContext } from "react";
import { Notification } from "./NotificationProvider";

type NotificationContext = {
  notifications: Notification[];
  setNotifications: Dispatch<SetStateAction<Notification[]>>;
};

export const NotificationContext = createContext<NotificationContext | null>(
  null
);
