import {
  getEnvironment,
  isProductionEnvironment,
} from "@/tools/environment/environment";
import { Box, Typography, useTheme } from "@mui/material";
import { Logo } from "mt-components";

type LogoWithEnvironmentProps = {
  height?: number;
  color?: string;
  top?: number | string;
  left?: number | string;
};
export default function LogoWithEnvironment({
  height = 42,
  color,
  top = "50%",
  left = "200%",
}: LogoWithEnvironmentProps) {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative" }}>
      <Logo height={height} color={color} />
      {!isProductionEnvironment() && (
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            left,
            top,
            transform: "translate(-50%, -50%)",
            color: theme.custom.sideBarTextColor,
          }}
        >
          {getEnvironment()}
        </Typography>
      )}
    </Box>
  );
}
