import { ColorModeContext } from "@/providers/ColorModeProvider/ColorModeContext";
import { useContext } from "react";

export default function useColorMode() {
  const context = useContext(ColorModeContext);
  if (!context) {
    throw new Error("ColorModeContext must be used within a ColorModeProvider");
  }

  return context;
}
