import {
  hasLowercase,
  hasSpecialChar,
  hasUppercase,
  isAtLeast8Chars,
} from "../../pages/ResetPasswordPage/validators";
import { EditPasswordError } from "./EditPasswordErrors";

export const validatePassword = (
  password: string,
  confirmationPassword: string
) => {
  const errors: EditPasswordError[] = [];
  if (!password || password !== confirmationPassword)
    errors.push("confirmationPasswordMismatch");
  if (!isAtLeast8Chars(password)) errors.push("passwordMinimumLength");
  if (!hasUppercase(password)) errors.push("passwordNeedsUppercase");
  if (!hasLowercase(password)) errors.push("passwordNeedsLowercase");
  if (!hasSpecialChar(password)) errors.push("passwordNeedsSpecialCharacter");
  return errors;
};
