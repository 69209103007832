import useVenuePortalUserOptions from "@/components/VenuePortalUserOptionsAutocomplete/useVenuePortalUserOptions";
import useUser from "@/hooks/useUser";
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from "@mui/material";

type VenuePortalUserOptionsAutocompleteProps = Omit<
  AutocompleteProps<
    ReturnType<
      typeof useVenuePortalUserOptions
    >["venuePortalUserOptions"][number],
    false,
    true,
    false
  >,
  "loading" | "value" | "options" | "onChange" | "renderInput"
> & { textFieldProps?: TextFieldProps; onChangeCallback?: () => void };
export default function VenuePortalUserOptionsAutocomplete({
  textFieldProps,
  onChangeCallback,
  ...props
}: VenuePortalUserOptionsAutocompleteProps) {
  const {
    venuePortalUserOptions,
    venuePortalUserOptionMap,
    getVenuePortalUsersResult: { loading },
  } = useVenuePortalUserOptions();

  const {
    user: { userId },
    impersonateUser,
  } = useUser();

  const value = venuePortalUserOptionMap.get(userId ?? "");

  return (
    <Autocomplete
      {...props}
      loading={loading}
      value={value}
      options={venuePortalUserOptions}
      getOptionLabel={({ label }) => label ?? ""}
      renderOption={(props, option) => {
        const { key, ...rest } = props as typeof props & { key: React.Key };
        return option.label !== null ? (
          <li {...rest} key={key}>
            {option.label}
          </li>
        ) : null;
      }}
      onChange={(_, user) => {
        impersonateUser(user?.id);
        onChangeCallback?.();
      }}
      renderInput={(params) => <TextField {...params} {...textFieldProps} />}
    />
  );
}
