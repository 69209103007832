import icons from "@/tools/icons";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useCallback, useState } from "react";

export default function PasswordTextField(props: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = useCallback(() => setShowPassword((show) => !show), []);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault(),
    []
  );

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClick}
              onMouseDown={handleMouseDown}
              edge="end"
            >
              {showPassword ? <icons.VisibilityOff /> : <icons.Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
