import CardStack from "@/components/ReservationDetails/components/CardStack/CardStack";
import { GroupedGuests, VenueReservation } from "@/graphql/operations";
import useLocalization from "@/hooks/useLocalization";
import { isPendingReservationUpdate } from "@/hooks/useReservation";
import icons from "@/tools/icons";
import { useMemo } from "react";
import GuestCard from "./GuestCard";

type GuestStackProps = { reservation: VenueReservation };
export default function GuestStack({ reservation }: GuestStackProps) {
  const { t } = useLocalization();

  const deltaGroupedGuests = useMemo(
    () => createDeltaGroupedGuests(reservation),
    [reservation]
  );

  const guestCards = useMemo(
    () =>
      deltaGroupedGuests.map(([name, quantities]) => (
        <GuestCard
          key={name}
          name={name}
          {...quantities}
          isReservationUpdate={isPendingReservationUpdate(reservation)}
        />
      )),
    [deltaGroupedGuests, reservation]
  ) as React.JSX.Element[];

  return (
    <CardStack
      emptyHeader={t("reservation:unconfirmedWhat", {
        interpolation: {
          what: t("reservation:guest"),
        },
      })}
      i18nCountkey={"reservation:guest"}
      Icon={icons.Person}
      originalCount={reservation.originalReservation?.numGuests}
      count={reservation.numGuests}
      reservation={reservation}
      cards={guestCards}
    />
  );
}

const createDeltaGroupedGuests = (reservation: VenueReservation) =>
  Object.entries(
    [
      {
        groupedGuests: reservation.group?.groupedGuests ?? [],
        key: "currentQuantity" as const,
      },
      {
        groupedGuests:
          reservation.originalReservation?.snapshot?.groupedGuests ?? [],
        key: "originalQuantity" as const,
      },
    ].reduce((acc, { groupedGuests, key }) => {
      groupedGuests.forEach(({ name, quantity }) => {
        if (!(name in acc)) acc[name] = {};
        acc[name]![key] = quantity;
      });
      return acc;
    }, {} as Record<GroupedGuests["name"], { currentQuantity?: number; originalQuantity?: number }>)
  );
