import { Dispatch, SetStateAction, createContext } from "react";
import { I18nKey, Localization } from "../../i18n/types";

type LocalizationContext = {
  localization: Localization;
  setLocalization: Dispatch<SetStateAction<Localization>>;
  t: (
    key: I18nKey,
    options?:
      | {
          localization?: Localization | undefined;
          interpolation?: object | undefined;
        }
      | undefined
  ) => string;
};

export const LocalizationContext = createContext<LocalizationContext | null>(
  null
);
