import { enLocale } from "./locales/en";

type ConcatNestedField<
  T,
  Prefix extends string = "",
  Nested extends boolean = false
> = {
  [K in keyof T]: T[K] extends object
    ? ConcatNestedField<
        T[K],
        `${Prefix}${K & string}${Nested extends true ? "." : ":"}`,
        true
      >
    : `${Prefix}${StripUnderscoreSuffix<K & string>}`;
}[keyof T];

export type I18nLocale = typeof enLocale;

type StripUnderscoreSuffix<T extends string> = T extends `${infer U}_${string}`
  ? U
  : T;

type AllowUnderscoreSuffix<T extends string, V> = V extends string
  ? T | `${T}_${string}`
  : T;

type DeepPartial<T extends object> = {
  [K in keyof T as AllowUnderscoreSuffix<
    StripUnderscoreSuffix<Extract<K, string>>,
    T[K]
  >]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export type PartialI18nLocale = DeepPartial<I18nLocale>;

export type I18nKey = ConcatNestedField<I18nLocale>;

export const localizations = ["en", "ja", "th"] as const;

export type Localization = (typeof localizations)[number];
