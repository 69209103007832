import { IconButton } from "@mui/material";

type DrawerProps = {
  open: boolean;
  handleClose: () => void;
};
type DrawerButtonProps = DrawerProps & {
  handleOpen: () => void;
  Icon: JSX.Element;
  Drawer: React.ComponentType<DrawerProps>;
};
export default function DrawerButton({
  handleClose,
  handleOpen,
  Icon,
  open,
  Drawer,
}: DrawerButtonProps) {
  return (
    <>
      <IconButton onClick={() => (open ? handleClose() : handleOpen())}>
        {Icon}
      </IconButton>
      <Drawer open={open} handleClose={handleClose} />
    </>
  );
}
