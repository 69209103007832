import {
  getEnvironment,
  isLocalEnvironment,
} from "@/tools/environment/environment";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

(async () => {
  Sentry.init({
    enabled: !isLocalEnvironment(),
    dsn: "https://da91d2c52984ebac9dd683d6eca7ab62@o4506425710018560.ingest.us.sentry.io/4507314795511808",
    environment: getEnvironment(),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        mask: [".sensitive"],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      " localhost",
      /^https:\/\/vp\.magical-trip.com\/api/,
    ],

    // how often replays are recorded
    replaysSessionSampleRate: 0.05,

    // how often replays are recorded when an error occurs
    replaysOnErrorSampleRate: 1.0,
  });
})();
