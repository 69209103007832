import { gql } from "@apollo/client";

export type SendVenuePortalFeedbackEmailInput = {
  body: string;
};

export type SendVenuePortalFeedbackEmailResponse = {
  sendVenuePortalFeedbackEmail: boolean;
};

export const SEND_FEEDBACK_EMAIL_MUTATION = gql`
  mutation SendVenuePortalFeedbackEmailMutation($body: String!) {
    sendVenuePortalFeedbackEmail(body: $body)
  }
`;
