import Stack from "@mui/material/Stack";
import DateTimeSummary from "./DateTimeSummary";
import GuestsAndGuidesSummary from "./GuestsAndGuidesSummary";
import { ReservationRowProps } from "./ReservationRow";
import StatusIcon from "./StatusIcon";

type ReservationSummaryProps = Pick<
  ReservationRowProps,
  "reservation" | "isSelected"
>;

export function ReservationSummary({ reservation }: ReservationSummaryProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <StatusIcon reservation={reservation} sx={{ flexGrow: 0 }} />
      <DateTimeSummary reservation={reservation} />
      <GuestsAndGuidesSummary reservation={reservation} />
    </Stack>
  );
}
