import LoadingButton from "@/components/LoadingButton/LoadingButton";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { FormProps } from "../types";
import LoginForm from "./LoginForm";

type RequestPasswordResetFormProps = FormProps;

export default function RequestPasswordResetForm({
  setForm,
}: RequestPasswordResetFormProps) {
  const [email, setEmail] = useState("");
  const { t } = useLocalization();
  const { requestPassword } = useUser();

  return (
    <>
      <TextField
        label={t("nouns:email")}
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <LoadingButton
        variant="contained"
        disabled={!email}
        endIcon={<icons.EditPassword />}
        onClick={async () => {
          await requestPassword(
            {
              email,
            },
            () => setForm(() => LoginForm)
          );
        }}
        fullWidth
      >
        {t("command:requestWhat", {
          interpolation: {
            what: t("settings:password.new"),
          },
        })}
      </LoadingButton>
    </>
  );
}
