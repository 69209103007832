import LoadingButton from "@/components/LoadingButton/LoadingButton";
import useLocalization from "@/hooks/useLocalization";
import useMailer from "@/hooks/useMailer";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";

export default function FeedbackPage() {
  const { t } = useLocalization();
  const [body, setBody] = useState("");
  const [isSent, setIsSent] = useState(false);
  const {
    sendVenuePortalFeedbackEmail,
    sendVenuePortalFeedbackEmailResult: { loading },
  } = useMailer();

  return (
    <Stack gap={1} sx={{ padding: "1rem" }}>
      <TextField
        disabled={loading || isSent}
        label="feedback"
        focused
        placeholder={t("messages:feedbackExplanation")}
        multiline
        minRows={8}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <LoadingButton
        disabled={!body || isSent || loading}
        variant="contained"
        onClick={async () => {
          await sendVenuePortalFeedbackEmail(body, {
            onCompleted: () => setIsSent(true),
          });
        }}
      >
        {isSent
          ? t("messages:feedbackGratitude")
          : t(`command:${loading ? "sending" : "send"}What`, {
              interpolation: {
                what: t("nouns:feedback"),
              },
            })}
      </LoadingButton>
    </Stack>
  );
}
