import PasswordTextField from "@/components/PasswordTextField/PasswordTextField";
import useLocalization from "@/hooks/useLocalization";
import { Dispatch, SetStateAction } from "react";
import EditPasswordButton, {
  EditPasswordButtonProps,
} from "./EditPasswordButton";
import EditPasswordErrors, {
  EditPasswordErrorProps,
} from "./EditPasswordErrors";

type EditPasswordFormProps = EditPasswordErrorProps &
  Pick<EditPasswordButtonProps, "requestId"> & {
    password: string;
    setPassword: Dispatch<SetStateAction<string>>;
    confirmationPassword: string;
    setConfirmationPassword: Dispatch<SetStateAction<string>>;
    onCompleted?: () => void;
  } & (
    | {
        currentPassword: string;
        setCurrentPassword: Dispatch<SetStateAction<string>>;
      }
    | NonNullable<unknown>
  );

export default function EditPasswordForm({
  errors,
  requestId,
  password,
  setPassword,
  confirmationPassword,
  setConfirmationPassword,
  onCompleted,
  ...props
}: EditPasswordFormProps) {
  const { t } = useLocalization();

  const [currentPassword, setCurrentPassword] =
    "currentPassword" in props
      ? [props.currentPassword, props.setCurrentPassword]
      : [undefined, undefined];

  return (
    <>
      <EditPasswordErrors errors={errors} />
      {currentPassword !== undefined && (
        <PasswordTextField
          label={t("settings:password.current")}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          fullWidth
        />
      )}
      <PasswordTextField
        label={t(
          currentPassword === undefined
            ? "nouns:password"
            : "settings:password.new"
        )}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
      />
      <PasswordTextField
        label={t("command:confirmWhat", {
          interpolation: {
            what: t(
              currentPassword === undefined
                ? "nouns:password"
                : "settings:password.new"
            ),
          },
        })}
        value={confirmationPassword}
        onChange={(e) => setConfirmationPassword(e.target.value)}
        fullWidth
      />
      <EditPasswordButton
        currentPassword={currentPassword}
        newPassword={password}
        disabled={!!errors.length}
        requestId={requestId}
        onCompleted={onCompleted}
      />
    </>
  );
}
