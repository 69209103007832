import { Guide } from "@/graphql/operations";
import useDevice from "@/hooks/useDevice";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Stack from "@mui/material/Stack";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { getCloudinaryUrl } from "mt-visuals/cloudinary";

type GuideCardProps = Pick<Guide, "name" | "phoneNumber" | "profilePic"> & {
  FallbackIcon?: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
};

export default function GuideCard({
  name,
  phoneNumber,
  profilePic,
  FallbackIcon,
}: GuideCardProps) {
  const { isDesktop } = useDevice();
  const avatarSize = isDesktop ? 116 : 96;

  return (
    <Card
      sx={{
        display: "flex",
        padding: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack direction="column">
        <CardContent
          sx={{
            paddingBottom: "16px !important",
          }}
        >
          <Typography component="div" variant="h5">
            {name}
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            color="text.secondary"
          >
            {phoneNumber ?? "No Phone Number"}
          </Typography>
        </CardContent>
      </Stack>
      <Avatar
        src={getCloudinaryUrl("image", profilePic ?? "", {
          width: avatarSize,
          height: avatarSize,
        })}
        sx={{
          width: avatarSize,
          height: avatarSize,
        }}
      >
        {FallbackIcon && <FallbackIcon sx={{ fontSize: avatarSize * 0.8 }} />}
      </Avatar>
    </Card>
  );
}
