import useSettings from "@/hooks/useSettings";
import { ColorMode } from "@/types";
import { ThemeProvider } from "@mui/material";
import deepPurple from "@mui/material/colors/deepPurple";
import orange from "@mui/material/colors/orange";
import createTheme from "@mui/material/styles/createTheme";
import { useCallback, useMemo } from "react";
import { ColorModeContext } from "./ColorModeContext";

export default function ColorModeProvider({
  children,
}: React.PropsWithChildren) {
  const { settings, setColorMode } = useSettings();

  const colorMode = useMemo(
    () => settings.theme as ColorMode,
    [settings.theme]
  );

  const toggleColorMode = useCallback(() => {
    setColorMode((colorMode) => {
      const nextColorMode = colorMode === "dark" ? "light" : "dark";
      return nextColorMode;
    });
  }, [setColorMode]);

  const theme = useMemo(() => {
    const isLightMode = colorMode === "light";
    return createTheme({
      palette: {
        primary: deepPurple,
        secondary: orange,
        mode: colorMode,
      },
      custom: {
        passwordErrorColor: "red",
        sideBarTextColor: "#FFFFFF",
        cardBackground: isLightMode ? "#F5F5F5" : "none",
        iconBadgeOpacity: (isLightMode: boolean, disabled: boolean) =>
          disabled ? (isLightMode ? 0.8 : 0.5) : 1,
        reservationSummaryFontWeight: "600",
      },
      components: {
        ...(isLightMode && lightModeStyleOverrides),
      },
    });
  }, [colorMode]);

  return (
    <ColorModeContext.Provider
      value={{
        colorMode,
        toggleColorMode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const lightModeStyleOverrides = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: "#F5F5F5",
      },
    },
  },
} as const;
