import { Box } from "@mui/material";
import "./MainContent.module.scss";
import s from "./MainContent.module.scss";

export default function MainContent({ children }: React.PropsWithChildren) {
  return (
    <Box className={s.MainContainer}>
      <main>{children}</main>
    </Box>
  );
}
