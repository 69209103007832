import useNotification from "@/hooks/useNotification";
import { Notification } from "@/providers/NotificationProvider/NotificationProvider";
import icons from "@/tools/icons";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";

type NotificationBarProps = {
  notification: Notification;
};

export default function NotificationBar({
  notification,
}: NotificationBarProps) {
  const { clearNotification } = useNotification();

  useEffect(() => {
    const timeout = setTimeout(() => {
      clearNotification(notification.id);
    }, notification.autoHideDuration ?? 10000);

    return () => clearTimeout(timeout);
  }, [clearNotification, notification.autoHideDuration, notification.id]);

  return (
    <Alert
      severity={notification?.severity}
      variant="filled"
      sx={{ alignItems: "center", color: "white", marginTop: "6px" }}
    >
      {notification?.message}
      <IconButton
        aria-label="close"
        color="inherit"
        sx={{ p: 0.5 }}
        onClick={() => clearNotification(notification.id)}
      >
        <icons.Close />
      </IconButton>
    </Alert>
  );
}
