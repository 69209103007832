import { useEffect, useMemo, useState } from "react";

const MOBILE_WIDTH_THRESHOLD = 768 as const;

type ScreenSize = {
  width: number;
  height: number;
};

export default function useDevice() {
  const [screenSize, setScreenSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = useMemo(
    () => screenSize.width < MOBILE_WIDTH_THRESHOLD,
    [screenSize]
  );

  const isDesktop = useMemo(() => !isMobile, [isMobile]);

  return {
    isDesktop,
    isMobile,
    screenSize,
  };
}
