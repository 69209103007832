export const localStorageKeys = {
  colorMode: "colorMode",
  localization: "localization",
  jwtToken: "jwtToken",
} as const;

export type LocalStorageKey = keyof typeof localStorageKeys;

export const getLocalStorage = (key: LocalStorageKey) =>
  localStorage.getItem(key);

export const setLocalStorage = (key: LocalStorageKey, value: string) =>
  localStorage.setItem(key, value);

export const removeLocalStorage = (key: LocalStorageKey) =>
  localStorage.removeItem(key);
