import { GA_MEASUREMENT_ID, configGa, gaIsSetup } from "@/tools/analytics";
import { isProductionEnvironment } from "@/tools/environment/environment";

if (isProductionEnvironment() && !gaIsSetup()) {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
  document.head.appendChild(script);

  script.onload = function () {
    window.dataLayer = window.dataLayer || [];

    function gtag(..._args: unknown[]) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    window.gtag = gtag;
    gtag("js", new Date());
    configGa();
  };
}
