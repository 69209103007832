import { GetVenueReservationsQueryResponse } from "@/providers/ReservationsProvider/queries/GetVenueReservationsQuery";
import { TypePolicies } from "@apollo/client";

type Reservations =
  GetVenueReservationsQueryResponse["venuePortalUser"]["reservations"];

export const venuePortalUserPolicies: TypePolicies = {
  VenuePortalUser: {
    fields: {
      reservations: {
        keyArgs: false,
        merge(existing: Reservations | undefined, incoming: Reservations) {
          const mergeResult: Reservations = {
            totalRecords: incoming.totalRecords,
            records: [...(existing?.records ?? []), ...incoming.records],
          };

          return mergeResult;
        },
      },
    },
  },
};
