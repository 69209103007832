import LoadingButton, {
  LoadingButtonProps,
} from "@/components/LoadingButton/LoadingButton";
import useLocalization from "@/hooks/useLocalization";
import useUser from "@/hooks/useUser";
import icons from "@/tools/icons";

export type EditPasswordButtonProps = Pick<LoadingButtonProps, "disabled"> & {
  currentPassword?: string;
  newPassword: string;
  requestId?: string;
  onCompleted?: () => void;
};
export default function EditPasswordButton({
  disabled,
  newPassword,
  requestId,
  currentPassword,
  onCompleted,
}: EditPasswordButtonProps) {
  const { t } = useLocalization();
  const { editPassword } = useUser();

  return (
    <LoadingButton
      variant="contained"
      disabled={disabled}
      endIcon={<icons.EditPassword />}
      onClick={async () => {
        await editPassword(
          {
            currentPassword,
            newPassword,
            requestId,
          },
          () => {
            onCompleted?.();
          }
        );
      }}
      fullWidth
    >
      {t("command:setWhat", {
        interpolation: {
          what: t("nouns:password"),
        },
      })}
    </LoadingButton>
  );
}
