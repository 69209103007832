import { gql } from "@apollo/client";

export type EditPasswordMutationInput = {
  newPassword: string;
  currentPassword?: string;
  requestId?: string;
};
export type EditPasswordMutationResponse = {
  editPassword: boolean;
};
export const EDIT_PASSWORD_MUTATION = gql`
  mutation EditPasswordMutation(
    $newPassword: String!
    $currentPassword: String
    $requestId: ID
  ) {
    editPassword(
      newPassword: $newPassword
      currentPassword: $currentPassword
      requestId: $requestId
    )
  }
`;
