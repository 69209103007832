import { VenueReservation } from "@/graphql/operations";
import { magicalTripLink } from "@/tools/links/magicalTripLinks";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

type ProductButtonProps = { reservation: VenueReservation };
export default function ProductButton({ reservation }: ProductButtonProps) {
  return (
    <Button
      fullWidth
      href={`${magicalTripLink}/product/${reservation?.group?.product?.id}`}
      target="_blank"
      sx={{ textTransform: "unset" }}
    >
      <Typography variant="h5" textAlign="center" sx={{ textWrap: "balance" }}>
        {reservation?.group?.product?.name}
      </Typography>
    </Button>
  );
}
