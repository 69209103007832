import { VenueReservation } from "@/graphql/operations";
import { gql } from "@apollo/client";
import { reservationsFragment } from "./GetVenueReservationsQuery";

export type GetVenueReservationQueryInput = {
  id: string;
  lang: string;
};
export type GetVenueReservationQueryResponse = {
  venueReservation: VenueReservation;
};

export const GET_VENUE_RESERVATION_QUERY = gql`
  query GetVenueReservation($id: ID!, $lang: String!) {
    venueReservation(id: $id) 
      ${reservationsFragment}
  }
`;
