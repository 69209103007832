import EditPasswordForm from "@/components/EditPasswordForm/EditPasswordForm";
import { validatePassword } from "@/components/EditPasswordForm/validatePassword";
import Stack from "@mui/material/Stack";
import { useMemo, useState } from "react";

type EditPasswordSettingsProps = {
  onSuccess?: () => void;
};
export default function EditPasswordSettings({
  onSuccess,
}: EditPasswordSettingsProps) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  const errors = useMemo(
    () => validatePassword(newPassword, confirmationPassword),
    [newPassword, confirmationPassword]
  );

  return (
    <Stack gap={1} alignItems="flex-start">
      <EditPasswordForm
        errors={errors}
        password={newPassword}
        setPassword={setNewPassword}
        confirmationPassword={confirmationPassword}
        setConfirmationPassword={setConfirmationPassword}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        onCompleted={onSuccess}
      />
    </Stack>
  );
}
