import PublicRouteTemplate from "@/components/PublicRouteTemplate/PublicRouteTemplate";
import { isValidSession } from "@/tools/jwtToken/jwtToken";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./forms/LoginForm";
import { FormProps } from "./types";

export type LoginPageForm = "login" | "passwordReset";

export default function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isValidSession()) navigate("/");
  }, [navigate]);

  const [Form, setForm] = useState<React.ComponentType<FormProps>>(
    () => LoginForm
  );

  return (
    <PublicRouteTemplate onClick={() => setForm(() => LoginForm)}>
      <Form setForm={setForm} />
    </PublicRouteTemplate>
  );
}
