import { SettingsContext } from "@/providers/SettingsProvider/SettingsProvider";
import { useContext } from "react";

export default function useSettings() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("SettingsContext must be used within a SettingsProvider");
  }

  return context;
}
